import React, { Component } from 'react';
import {Link, NavLink} from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { iconos_slide } from '../../../../utility/icons';
import ImageDashbordD from '../../../../../assets/img/icons/Dashboard.png';
import ImageDashbordA from '../../../../../assets/img/icons/Dashboard1.png';
import ImageAdminD from '../../../../../assets/img/icons/Admin.png';
import ImageAdminA from '../../../../../assets/img/icons/Admin1.png';
import ImageCajaD from '../../../../../assets/img/icons/Caja.png';
import ImageCajaA from '../../../../../assets/img/icons/Caja1.png'
import ImageCuentasD from '../../../../../assets/img/icons/Cuentas.png';
import ImageCuentasA from '../../../../../assets/img/icons/Cuentas1.png'
import ImageBodegaD from '../../../../../assets/img/icons/Bodega.png';
import ImageBodegaA from '../../../../../assets/img/icons/bodega1.png';
import GastosD from '../../../../../assets/img/icons/gastos.png';
import GastosA from '../../../../../assets/img/icons/gasto1.png'
import OrdenCompraD from '../../../../../assets/img/icons/oc.png';
import OrdenCompraA from '../../../../../assets/img/icons/oc1.png';
import OrdenVentaD from '../../../../../assets/img/icons/ov.png';
import OrdenVentaA from '../../../../../assets/img/icons/ov1.png'
import ReporteD from '../../../../../assets/img/icons/reporte.png';
import ReporteA from '../../../../../assets/img/icons/reporte1.png'
import PlanillaD from '../../../../../assets/img/icons/planilla.png';
import PlanillaA from '../../../../../assets/img/icons/planilla1.png';
import VehiculosD from '../../../../../assets/img/icons/Vehiculos.png';
import VehiculosA from '../../../../../assets/img/icons/Vehiculos1.png';
import RutaA from '../../../../../assets/img/icons/road.svg';
import RutaD from '../../../../../assets/img/icons/road1.svg';
import notificacionesD from '../../../../../assets/img/icons/notifiacion.png';
import notificacionesA from '../../../../../assets/img/icons/notifiacion1.png';
import DespachosPlantaA from '../../../../../assets/img/icons/despacho_planta_activo.png';
import DespachosPlantaD from '../../../../../assets/img/icons/despacho_planta_inactivo.png';

class SideBar extends Component {
    constructor(props) {
        super(props);
    }
    getPermisosReportes = () => {
        const {permisos} = this.props
        if (permisos.bitacora || permisos.deuda || permisos.deuda_interna || permisos.cuentasc || permisos.cuentasp || permisos.ingresoegreso || permisos.ingresoegreso_interno ||
            permisos.pago_flotante || permisos.reporte_vehiculos || permisos.reporte_vales || permisos.cobros_mes || permisos.ventas_mes ||
            permisos.cuentas_mes || permisos.cuentas_mes_pagar || permisos.producto_vendido || permisos.reporte_produccion || permisos.reporte_consumo || permisos.precios_cliente ||
            permisos.precios_producto || permisos.actividad_pilotos || permisos.costo_inventario || permisos.costovsventas || permisos.ingresos_producto ||
            permisos.rep_ausencias || permisos.rep_pagoplanilla || permisos.repo_pagoplanilla_empresa || permisos.aumento_precios || permisos.precios_internos || permisos.reporte_materia_prima){
                return true
            }
        else{return false}
    }
    getPermisosbodegas = () => {
        const {permisos} = this.props
        if (permisos.sim || permisos.ver_sim || permisos.bodegas || permisos.ver_bodegas)
            return true
        else
            return false
    }
    gerPermisosAdmin = () =>{
        const {permisos} = this.props
        if (permisos.usuarios || permisos.empresas || permisos.vendedores || permisos.clientes  || permisos.proveedores ||
            permisos.productos || permisos.tproducto || permisos.categiagastos || permisos.puestos || permisos.regiones || permisos.roles ||
            permisos.ver_usuarios || permisos.ver_empresas || permisos.ver_establecimientos || permisos.ver_vendedores || permisos.ver_clientes  || permisos.ver_proveedores ||
            permisos.ver_productos || permisos.ver_tproducto || permisos.ver_categiagastos || permisos.ver_puestos
            || permisos.ver_regiones || permisos.ver_roles || permisos.ver_pilotos || permisos.pilotos || permisos.poss || permisos.ver_poss){
                return true
            }
        else{ return false}
    }
    render() {
        const { toggleOpen, navToggle, logOut, permisos } = this.props;
        return (
            <aside className={`display-print main-sidebar px-0 col-12 col-md-3 col-lg-2 ${toggleOpen?'':'open'}`}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light  bg-primary">
                        <a  href="#" className="w-100 mr-0 navbar-brand" >
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require('assets/img/logoN.png')}
                                    alt="Logo" />
                            </div>
                        </a>
                        <a  className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper bs-nav">
                    <Accordion>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <NavLink to="/" exact className="nav-link " activeClassName={'active'}>
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <img className="dashbord-img" src={expanded ? ImageDashbordA: ImageDashbordD}></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>DASHBOARD</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ImageCajaA: ImageCajaD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>CAJA</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {permisos.cajaschicas && (<div className="sub-nav-item">
                                                    <NavLink exact to="/cajas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cajas chicas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                <div className="sub-nav-item">
                                                    <NavLink exact to={`/caja/${this.props.caja}`} activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Mi Caja</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <NavLink to="/notificaciones" exact className="nav-link " activeClassName={'active'}>
                                                    <div className="d-inline-block item-icon-wrapper">
                                                        <img className="dashbord-img" src={expanded ? notificacionesA: notificacionesD}></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>NOTIFICACIONES</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                               permisos.gastos && <NavLink exact to="/gastos" style={{textDecoration:'None'}}>
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? GastosA: GastosD} />
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>GASTOS</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>

                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                this.getPermisosbodegas() && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ImageBodegaA: ImageBodegaD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>BODEGAS</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.bodegas || permisos.ver_bodegas) &&(<div className="sub-nav-item">
                                                    <NavLink exact to="/bodegas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Bodegas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_sim || permisos.sim) &&(<div className="sub-nav-item">
                                                    <NavLink exact to="/solicitud_interna" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Solicitud I.M.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>

                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                               (permisos.ordencompra || permisos.peticionmateriales || permisos.ingresos_oc ) && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? OrdenCompraA: OrdenCompraD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>O.C.</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                { (permisos.ordencompra || permisos.ingresos_oc) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/ordencompras" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Orden C.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                { (permisos.ordencompra || permisos.peticionmateriales) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/peticiones" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Peticion M.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>

                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.ordenventa || permisos.peticionventa) && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? OrdenVentaA: OrdenVentaD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>O.V.</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {permisos.ordenventa &&(<div className="sub-nav-item">
                                                    <NavLink exact to="/ordenesventa" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Orden V.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}

                                               { permisos.peticionventa && (<div className="sub-nav-item">
                                                    <NavLink exact to="/peticionesventa" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Peticion V.</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                this.getPermisosReportes() && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ReporteA: ReporteD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>REPORTES</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.bitacora && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_bitacoras" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Bitácora</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.deuda &&<AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_deuda" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Deuda</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.deuda_interna &&<AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_deuda_interna" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Deuda Interna</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.cuentasp && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/cuentas_por_pagar" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cuentas por Pagar</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.cuentasc && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/cuentas_por_cobrar" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cuentas por Cobrar</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.ingresoegreso && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_ingreso" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Ingreso Egreso</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.ingresoegreso_interno && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_ingreso_interno" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Ingreso Egreso Interno</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.pago_flotante && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_pago_flotante" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Pago flotante</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_vehiculos && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_maquinaria_vehiculos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Maquinaria/vehículos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_vales && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_vales_gasolina" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}> Vales de gasolina </span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.cobros_mes && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_cobros" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cobros por mes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.ventas_mes && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_ventas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Ventas por mes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.cuentas_mes && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_cuentas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cuentas por cobrar por mes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_despachos_bancos && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte-despachos-bancos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Reporte Despachos-Bancos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_comisiones_tarjetas && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte-comisiones-tarjetas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Reporte Comisiones Tarjetas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.cuentas_mes_pagar && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_cuentas_pagar" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Cuentas por pagar por mes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.aumento_precios && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/aumento_productos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Aumento de precios venta</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.precios_internos && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/precios_internos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Precios Internos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.producto_vendido && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_productos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Producto más vendido</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_produccion && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_produccion" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Producción</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_consumo && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_consumo" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Productos Consumidos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.precios_cliente && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/precios_clientes" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Precios de clientes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.precios_producto && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/precios_productos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Precios de productos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.actividad_pilotos && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_pilotos" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Actividad de Pilotos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.reporte_materia_prima && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_materia_prima" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Materia prima</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.costo_inventario && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/costos_inventario" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Costos de Inventario</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.costovsventas && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_costovsventas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Costo de Producto vs Ventas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.ingresos_producto && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_ingresos_producto" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Ingresos por Producto</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.rep_ausencias && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_aunsecias" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Reporte de Ausencias</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.rep_pagoplanilla && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_pagos_Empleado" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Pago de Planilla/Empleado</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            permisos.repo_pagoplanilla_empresa && <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/reporte_pagos_Empresa" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Pago de Planilla/Empresa</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                permisos.bancos && <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="Cuentas-img" src={expanded ? ImageCuentasA: ImageCuentasD}></img>
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>CUENTAS</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/cuentas" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            {/* <i className="material-icons">vertical_split</i> */}
                                                            <span className={"t-musgo"}>Bancos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.orden_produccion || permisos.ver_orden_produccion) && (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="Cuentas-img" src={expanded ? iconos_slide.produccion_activo: iconos_slide.produccion_inactivo}></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>PRODUCCIÓN</span>
                                                    </div>
                                                )
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                <div className="sub-nav-item">
                                                    <NavLink exact to="/produccion" activeClassName={"submenu-select"}>
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            {/* <i className="material-icons">vertical_split</i> */}
                                                            <span className={"t-musgo"}>Ordenes producción</span>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.devolucion_cli || permisos.ver_devolucion_cli ||
                                                    permisos.devolucion_pro || permisos.ver_devolucion_pro) && (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="Cuentas-img" src={expanded ? iconos_slide.devolucion_activo: iconos_slide.devolucion_inactivo}></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>DEVOLUCIÓN</span>
                                                    </div>
                                                )
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {
                                                    (permisos.devolucion_cli || permisos.ver_devolucion_cli) && (
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to="/devoluciones" activeClassName={"submenu-select"}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <span className={"t-musgo"}>Dev Clientes</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (permisos.devolucion_pro || permisos.ver_devolucion_pro) && (
                                                        <div className="sub-nav-item">
                                                            <NavLink exact to="/devolucionespro" activeClassName={"submenu-select"}>
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    {/* <i className="material-icons">vertical_split</i> */}
                                                                    <span className={"t-musgo"}>Dev Proveedores</span>
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    )
                                                }
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>

                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.puestos || permisos.ver_puestos || permisos.ver_bonificaciones || permisos.bonificaciones ||
                                                    permisos.ver_empleados || permisos.empleados || permisos.ver_planilla || permisos.planilla ||
                                                    permisos.ver_actividades || permisos.actividades || permisos.ver_asig_actividades || permisos.asig_actividades||
                                                    permisos.ausencias || permisos.ver_ausencias) && (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="Cuentas-img" src={expanded ? PlanillaA:PlanillaD }></img>
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>PLANILLA</span>
                                                    </div>
                                                )
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.puestos || permisos.ver_puestos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/puestos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Puestos de trabajo</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_bonificaciones || permisos.bonificaciones)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/bonificaciones" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Bonificaciones</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_empleados || permisos.empleados)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/empleados" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Empleados</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_planilla || permisos.planilla)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/planilla" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Pago de Planilla</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_actividades || permisos.actividades)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/actividades" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Actividades</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_asig_actividades || permisos.asig_actividades)&&(
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to="/asignacion_actividades" activeClassName={"submenu-select"} >
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span className={"t-musgo"}>Asignar Actividades</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                )}
                                                {( permisos.ausencias || permisos.ver_ausencias)&&(
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to="/ausencias" activeClassName={"submenu-select"} >
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span className={"t-musgo"}>Ausencias</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                )}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.ver_vehiculos || permisos.vehiculos ||
                                                    permisos.ver_vales || permisos.vales) && (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? VehiculosA: VehiculosD} />
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>VEHÍCULOS</span>
                                                    </div>
                                                )
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.ver_vehiculos || permisos.vehiculos) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/vehiculos_maquinaria" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Maquinaria/Vehiculos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.ver_vales || permisos.vales) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/vales_gasolina" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Vales de gasolina</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    <AccordionItemState>
                                            {({ expanded }) => (
                                               permisos.despachos_planta && <NavLink exact to="/despachosplanta" style={{textDecoration:'None'}}>
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? DespachosPlantaA: DespachosPlantaD} />
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>DESPACHOS PLANTA</span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                (permisos.ver_itinerarios || permisos.itinerarios ||
                                                    permisos.ver_pagos_itinerarios || permisos.pagos_itinerarios) && (
                                                    <div  className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                        <img className="dashbord-img" src={expanded ? RutaA: RutaD} />
                                                        <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `}>RUTAS</span>
                                                    </div>
                                                )
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.ver_itinerarios || permisos.itinerarios) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/itinerario" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Itinerarios</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.ver_pagos_itinerarios || permisos.pagos_itinerarios) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/pagos_itinerario" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Pagos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading >
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                this.gerPermisosAdmin() && <div className={`${expanded ? 'active-M' : ''} nav-link`}>
                                                    <img className="dashbord-img" src={expanded ? ImageAdminA: ImageAdminD} />
                                                    <span className={expanded ? 't-mostaza bold-sidebar': `bold-sidebar `} >ADMIN</span>
                                                </div>
                                            )}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {
                                        ({expanded})=>(
                                            <AccordionItemPanel className={expanded ? 'bg-submenu' : ''}>
                                                {(permisos.usuarios || permisos.ver_usuarios) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/usuarios" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Usuarios</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.empresas || permisos.ver_empresas)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/empresas" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Empresas</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.establecimientos || permisos.ver_establecimientos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/establecimientos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Establecimientos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.vendedores || permisos.ver_vendedores) && (<div className="sub-nav-item">
                                                    <NavLink exact to="/vendedores" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper dot-sub-item">
                                                            <span className={"t-musgo"}>Vendedores</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.clientes || permisos.ver_clientes)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/clientes" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper ">
                                                            <span className={"t-musgo"}>Clientes</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.proveedores || permisos.ver_proveedores)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/proveedores" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper ">
                                                            <span className={"t-musgo"}>Proveedores</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.productos || permisos.ver_productos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/productos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Productos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.tproducto || permisos.ver_tproducto)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/tipoproductos" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Tipo productos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.roles || permisos.ver_roles)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/roles" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Roles</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.regiones || permisos.ver_regiones)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/regiones" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Regiones</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.pilotos || permisos.ver_pilotos)&&(
                                                    <div className="sub-nav-item">
                                                        <NavLink exact to="/pilotos" activeClassName={"submenu-select"} >
                                                            <div className="d-inline-block item-icon-wrapper">
                                                                <span className={"t-musgo"}>Pilotos</span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                )}
                                                {(permisos.categiagastos || permisos.ver_categiagastos)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/admin_tipocategoria" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Categorias Gastos</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                                {(permisos.poss || permisos.ver_poss)&&(<div className="sub-nav-item">
                                                    <NavLink exact to="/poss" activeClassName={"submenu-select"} >
                                                        <div className="d-inline-block item-icon-wrapper">
                                                            <span className={"t-musgo"}>Poss</span>
                                                        </div>
                                                    </NavLink>
                                                </div>)}
                                            </AccordionItemPanel>
                                        )
                                    }
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                    </Accordion>

                </div>
            </aside>
        )
    }
}

export default SideBar;
