import React, { Component, Fragment } from 'react';
import {ProgressBar, Step} from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import PropTypes from 'prop-types';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardFormulario from '../../../Utils/Cards/cardFormularioOrden';

// formularios
import PeticionesVForm from './PVform';
import PagoOCForm from './pagoOCForm';
import EntregaOCForm from './entregaOCForm';

class PeticionForm extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'NUEVA PETICIÓN',
        texto_descripcion: 'Datos Petición de Venta',
        step: 1,
        justificacion: false,
        mostrar_precios: false,
        ordenes_venta: false
    }
    componentDidMount() {
        // this.props.selectEmpresas();
        if (this.props.match.params.id) {
            let id = this.props.match.params.id
            this.setState({editar: true, mostrar_precios: true, titulo:'EDITAR PETICIÓN', ordenes_venta: false});
            this.props.leer(id);
            if(this.props.match.path == '/ordenesventa/:id') {
                this.setState({titulo: 'EDITAR VENTA', texto_descripcion: 'Datos de Venta', ordenes_venta: true});
            }
        }else{
            if(this.props.match.path == '/ordenesventa/:id') {
                this.setState({titulo: 'EDITAR VENTA', ordenes_venta: true})
            }
            this.props.clear()
            this.props.setCliente(null)
        }

    }
    handleSubmit = (values) => {
        // Id de la cuenta a la que pertenece la nueva transacción
        let id = this.props.match.params.id;
    
        // Redondear los subtotales a dos decimales
        values.detalle_orden = values.detalle_orden.map(item => {
            const cantidad = parseFloat(item.cantidad);
            const precioUnitario = parseFloat(item.precio_unitario);
            const subtotal = cantidad * precioUnitario;

            return {
                ...item,
                subtotal: subtotal.toFixed(2)
            };
        });
        // Calcular el subtotal general sumando los subtotales redondeados de cada ítem
        const subtotalesRedondeados = values.detalle_orden.map(item => parseFloat(item.subtotal));
        const subtotalGeneral = subtotalesRedondeados.reduce((total, subtotal) => total + subtotal, 0).toFixed(2);
        console.log(subtotalGeneral);
        values.subtotal = subtotalGeneral
        let descuento = 0;
        values.detalle_orden.forEach(x => {
            if (x.descuento !== undefined && x.descuento !== null) {
                descuento += x.descuento;
            }
        });
        if(descuento){
            values.monto = parseFloat(subtotalGeneral - descuento).toFixed(2)
        }else{
            values.monto = subtotalGeneral
        }
        
        let dataForm = _.cloneDeep(values);
        dataForm.tipo_orden = 6;
        dataForm.direccion = dataForm.direccion.value;
    
        const { crear, editar } = this.props;
        let ruta_regreso = null;
    
        if (this.state.ordenes_venta) {
            ruta_regreso = '/ordenesventa';
        }
    
    
        this.state.editar ? editar(id, dataForm, ruta_regreso) : crear(dataForm);
    }
    
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };
    render() {
        const { loader, empresas, item } = this.props;
        const {step} = this.state

        return (
            <CardFormulario
            step={this.state.step}
            titulo={this.state.titulo}
            >
                <LoadMask loading={loader} light >
                    <Fragment>
                        {
                            item.justificacion &&((
                                item.justificacion.length > 0 &&(
                                    <div className="row justify-content-center text-center">
                                        <div className="col-12 col-md-10" style={{backgroundColor:"#e8e8e8"}}>
                                            <br/>
                                            <h6 className="t-mostaza text-uppercase" >Justificacion de rechazo: </h6>
                                            <p style={{fontWeight:600}} className="t-primary">{item.justificacion[item.justificacion.length -1].justificacion}</p>
                                        </div>
                                    </div>
                                )
                            ))
                        }
                        <br/>
                        {
                            step === 1 &&(
                                <PeticionesVForm
                                    mostrar_precios={this.state.mostrar_precios}
                                    onSubmit={this.handleSubmit}
                                    empresas={empresas}
                                    onSubmit={this.nextStep}
                                    texto_descripcion={this.state.texto_descripcion}
                                    {...this.props}
                                    initialValues={{
                                        'pago_inmediato': 'true',
                                        'tipo_cuenta': 'true',
                                        'fecha': new Date(),
                                        'fecha_prox_entrega': new Date(),
                                        'detalle_orden': [{cantidad: 1}],
                                        'dias_al_credito': 0
                                    }}
                                    setNits={this.props.setNits}
                                />
                            )
                        }
                        {
                            step === 2 &&(
                                <PagoOCForm
                                    role={this.props.role}
                                    previousStep={this.previousStep}
                                    onSubmit={this.nextStep}
                                    setDias_de_credito={this.props.setDias_de_credito}
                                    setNombre_nit={this.props.setNombre_nit}
                                    es_cf={this.props.es_cf}
                                    setCF={this.props.setCF}
                                    validateNit={this.props.validateNit}
                                    tieneNits={this.props.tieneNits}
                                    setDataName={this.props.setDataNamePeticionVentaForm}
                                    getFieldFormArray={this.props.getFieldFormArray}
                                    setFieldFormArray={this.props.setFieldFormArray}
                                />)
                            }
                            {
                                step === 3 && (
                                    <EntregaOCForm
                                        onSubmit={this.handleSubmit}
                                        previousStep={this.previousStep}
                                        initialValues={{
                                            'fecha_prox_entrega': new Date()
                                        }}
                                    />
                                )
                            }
                    </Fragment>
                </LoadMask>
            </CardFormulario>

        );
    }
}

export default PeticionForm;
