import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardFormulario from '../../../Utils/Cards/cardFormulario';
import PossForm from './PossForm';

import {api} from 'api';


let listado_cuentas = []

class PossCrear extends Component {
    static propTypes = {
        crear: PropTypes.func.isRequired,
    };
    state ={
        editar: false,
        titulo: 'AGREGAR POSS',
        cuentas: []
    }
    componentWillMount(){
        this.props.selectCuentas(null, null);
        if(this.props.match.params.id != null && this.props.match.params.id > 0){
            this.props.leer(this.props.match.params.id)
            this.setState({
                editar:true,
                titulo: 'EDITAR POSS'
            });
        }
    }
    onSubmit = (values) =>{
        const {crear, editar} =this.props
        let id = this.props.match.params.id;
        let valor = _.cloneDeep(values);
        this.state.editar? editar(id, valor) : crear(valor);
    }
    render() {
        const { loader, cuentas_banco, onChangeSwichAplicarCobro, aplicarCobro } = this.props;
        let cuentasDefault = [{
            cuota: 1,
            porcentaje_comision: 0,
        }]
        cuentasDefault = !this.state.editar ? cuentasDefault : []

        return (
            <CardFormulario titulo={this.state.titulo}>
                <LoadMask loading={loader} light type={"TailSpin"}>
                    <PossForm
                        initialValues={{
                            cuotas: cuentasDefault,
                            porcentaje_retencion_ISR: 0,
                            monto_primera_transaccion: 0,
                            monto_primera_transaccion: 0,
                        }}
                        cuentas={cuentas_banco}
                        onSubmit={this.onSubmit}
                        onChangeSwichAplicarCobro={onChangeSwichAplicarCobro}
                        aplicarCobro={aplicarCobro}/>
                </LoadMask>
            </CardFormulario>
        );
    }
}
export default PossCrear;