import React, { Component } from 'react'
import DespachoGrid  from './despachogrid';
import {Link} from 'react-router-dom'
import Modal from 'react-responsive-modal';

import HistoriaDespachosGrid from './historydespacho';
import HistorialEntregas from './historialEntregas';

export default class DespachoContainer extends Component {
    state={
        open_modal: false,
        modalEntregas: false,
    }

    setOpenModal = (val) =>{
        this.setState({
            open_modal:val,
        })

        if (val){
            console.log('bandera')
            let data = {orden:this.props.orden.id}
            this.props.HistoryDispatch(data)
        }
    };

    setOpenEntregas = () => {
        this.props.historialEntregas(this.props.orden.id);
        this.setState({modalEntregas: true});
    };

    setDespachar= ()=>{
        this.props.AutorizarDespacho(
            this.props.orden.id
        )
    };

    renderModalEntregas = () => {
        return (
            <Modal open={this.state.modalEntregas} onClose={(e) => {
                this.setState({modalEntregas: false});
            }} center>
                <div className="">
                    <h2 className="text-center text-primary text-uppercase">Historial de Entregas</h2>
                </div>
                <HistorialEntregas
                    data={this.props.historial}
                />
            </Modal>
        )
    }

    /* botonDespachoPDF = (id, row) => {
        return (
            <div className="">
                <a className="px-2 t-musgo" style={{cursor: "pointer"}}
                    target="_blank"
                    href={row.archivo}>
                    <img
                        className="action-img"
                        title="PDF Despacho"
                        src={require("../../../../../../../assets/img/icons/Cuentas1.png")} alt="PDF"/>
                </a>
            </div>
        )
    } */

    botonDespachoPDF = (id, row) => {
        return (
            <a
                className="px-2"
                style={{cursor: "pointer", color: "#c4183c"}}
                onClick={(e)=>{
                    e.preventDefault();
                    this.props.pdfItinerario_OV(row.id)
                }}>
                <img
                    className="action-img"
                    title="PDF Despacho"
                    src={require("../../../../../../../assets/img/icons/Cuentas1.png")} alt="PDF"/>
            </a>
        )
    }

    botonFacturaPDF = (id, row) => {
        return (
            <div className="">
                {/* <span className="font-weight-bold">PDF:</span> */}
                <a className="px-2 t-musgo" style={{cursor: "pointer"}}
                    target="_blank"
                    href={row.factura_despacho.factura}>
                    <img
                        className="action-img"
                        title="PDF Factura"
                        src={require("../../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            </div>
        )
    }

    render() {
        const { cuentas, orden, permisos, rol } = this.props;
        return (
            <div>
                {this.renderModalEntregas()}
                <Modal open={this.state.open_modal} onClose={(e) => {
                    this.setOpenModal(false)
                }} center>
                    <div className="">
                        <h2 className="text-center text-primary text-uppercase">Historial de despachos</h2>
                    </div>
                    <HistoriaDespachosGrid
                        data={this.props.data}
                        botonDespachoPDF={this.botonDespachoPDF}
                        botonFacturaPDF={this.botonFacturaPDF}
                    />
                </Modal>
                <DespachoGrid
                    detalle_orden={orden.detalle_orden}
                />

                <div className="row  justify-content-sm-start justify-content-start">
                    {
                        orden.a_despachar ? (
                            <div className="col-12">
                                {
                                    !orden.entregado && orden.estado && (!permisos.visualizar_mis_datos | rol == "VENDEDOR/SUPERIOR") ? (
                                        <Link  className="btn btn-primary m-1 align-self-center" to={`/despacho/ordenventa/${orden.id}`} >DESPACHAR</Link>
                                    ):
                                    ""
                                }
                                <button className="btn btn-mostaza m-1 align-self-center" onClick={ (e)=>{this.setOpenModal(true)}}>HISTORIAL</button>
                                <button className="btn btn-secondary m-1 align-self-center" onClick={this.setOpenEntregas}>ENTREGAS</button>
                                {/* <button className="btn btn-mostaza m-1 align-self-center">MARCAR DESPACHADA</button> */}
                            </div>
                        ):(
                            <div className="col-12">
                                <button className="btn btn-primary m-1 align-self-center" onClick={e =>{this.setDespachar()}} >AUTORIZAR</button>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}
