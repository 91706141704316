import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import roles from './modules/roles/roles';
import empresas from './modules/Empresas/empresas';
import establecimientos from './modules/Establecimientos/establecimientos';
import cuentas from './modules/cuentaBancos/cuentas';
import clientes from './modules/clientes/clientes';
import transacciones  from  './modules/Transacciones/transacciones';

import tipoproducto from './modules/producto/tipoproducto';
import productos from './modules/producto/producto';
import bodegas from './modules/bodega/bodega';
import fcm from './modules/fcm';

import vendedores from './modules/vendedores/vendedores';
import pilotos from './modules/pilotos/pilotos';
import ordencompra from './modules/OrdenCompra/ordencompra';
import notasCredito from  './modules/OrdenCompra/notasCredito';
import peticionM from  './modules/PeticionMaterial/peticionmaterial';
import tipocategoria from './modules/TipoCategorias/tipocategorias';

import bitacora from './modules/bitacora/bitacora';
import itinerario from './modules/itinerarios/itinerarios';
import gastos from './modules/gastos/gastos';
import caja from './modules/cajachica/caja';
import regiones from './modules/regiones/regiones'
import peticionV from './modules/PeticionVenta/peticionventa';
import ordenventa from './modules/OrdenVenta/ordenventa';
import ventainterna from './modules/OrdenVenta/ventainterna';
import Reportes from './modules/Reporte/deuda';
import ReportesInternos from './modules/Reporte/deuda_interna';
import reporteVehiculos from './modules/Reporte/reporteVehiculos'
import reportePlanilla from './modules/Reporte/reportePlanilla'
import reporteValesGasolina from './modules/Reporte/reportevales';
import poss from './modules/poss/poss';
import r_ingreso_egreso from './modules/Reporte/ingreso_egreso';
import r_ingreso_egreso_interno from './modules/Reporte/ingreso_egreso_interno';
import r_pago_flotante from './modules/Reporte/pago_flotante';
import reporteCobros from './modules/Reporte/reporteCobros';
import reporteVentas from './modules/Reporte/reporteVentas';
import reporteDespachosBancos from './modules/Reporte/reporteDespachosBancos';
import reporteComisionesTarjetas from './modules/Reporte/reporteComisionesTarjetas';
import reportecuentas from './modules/Reporte/reportecuentas';
import reportecuentaspagar from './modules/Reporte/reportecuentaspagar'
import reporteProductos from './modules/Reporte/reporteProductos';
import reporteAumentoPrecios from './modules/Reporte/reporteAumentoPrecios';
import reportePreciosInternos from './modules/Reporte/reportePreciosInternos';
import reporteProduccion from './modules/Reporte/reporteProduccion';
import reportePrecioCliente from './modules/Reporte/reportePreciosClientes';
import reportePrecioProducto from './modules/Reporte/reportPreciosProducto';
import reportePilotos from './modules/Reporte/reportePilotos';
import reporteCostoInventario from './modules/Reporte/reporteCostoInventario';
import reporteMateriaPrima from './modules/Reporte/reporteMateriaPrima';
import reporteCostoVenta from './modules/Reporte/reporteCostoVenta';
import reporteIngresos from './modules/Reporte/reporteIngresos';
import dashboard from './modules/Reporte/dashboard';

// planilla
import puestos from './modules/Puestos/Puestos';
import flotante_inverso from './modules/Transacciones/flotante_inverso';
import bonificacion from './modules/bonificaciones/bonificacion';
import empleados from './modules/Empleados/empledos';
import planilla from './modules/Planilla/planilla';

// bodega
import estado_bodega from './modules/estado_bodega/estado_bodega';
import devolucion from './modules/Devolucion/devolucion';

import actividades from './modules/Actividades/actividades';
import asignacionActividades from './modules/Actividades/asignacionActividades';

import vehiculosMaquinaria from './modules/Vehiculos/vehiculosMaquinaria';
import valesGasolina from './modules/ValesGasolina/valesGasolina';

// produccion
import produccion from './modules/OrdenProduccion/ordenproduccion';

import pagosItinerarios from './modules/PagosItinerarios/pagosItinerarios';
import liquidaciones from './modules/liquidaciones/liquidaciones';
import bandejaNotificaciones from './modules/bandeja/notifiacaciones';

import ausencias from './modules/Ausencias/ausensias';

import despachosplanta from './modules/despachosplanta/despachosplanta';

import bodegaUsuario from './modules/bodegaUsuario/bodegaUsuario';
export default combineReducers({
    form: formReducer,
    actividades,
    asignacionActividades,
    login,
    devolucion,
    register,
    profile,
    usuarios,
    roles,
    empresas,
    establecimientos,
    routing,
    notificaciones,
    cuentas,
    clientes,
    liquidaciones,
    transacciones,
    tipoproducto,
    productos,
    itinerario,
    bodegas,
    fcm,
    vendedores,
    pagosItinerarios,
    pilotos,
    ordencompra,
    peticionM,
    notasCredito,
    bitacora,
    tipocategoria,
    gastos,
    caja,
    peticionV,
    regiones,
    ordenventa,
    dashboard,
    Reportes,
    ReportesInternos,
    reporteVehiculos,
    reporteCobros,
    reporteVentas,
    reportecuentas,
    reporteDespachosBancos,
    reporteComisionesTarjetas,
    reportecuentaspagar,
    reporteValesGasolina,
    reporteProductos,
    reporteAumentoPrecios,
    reportePreciosInternos,
    reporteProduccion,
    reportePrecioCliente,
    reportePrecioProducto,
    reportePilotos,
    reporteCostoInventario,
    reporteMateriaPrima,
    reporteCostoVenta,
    reporteIngresos,
    valesGasolina,
    puestos,
    poss,
    ventainterna,
    flotante_inverso,
    r_ingreso_egreso,
    r_ingreso_egreso_interno,
    r_pago_flotante,
    bonificacion,
    empleados,
    estado_bodega,
    planilla,
    produccion,
    vehiculosMaquinaria,
    bandejaNotificaciones,
    ausencias,
    reportePlanilla,
    despachosplanta,
    bodegaUsuario
});
