import React, {Component} from 'react'
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from '../../../../Utils/Grid/index';
import {Monedas, getsimbolo} from '../../../../../../utility/constants';
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import {RenderCurrency, RenderNumber, RenderDateTimeAll} from "../../../../Utils/renderField/renderReadField";
import moment from 'moment';


class  DetalleGrid extends Component {
    render(){
        const { data, loader, listar: onPageChange, onSortChange, } = this.props
        return(
            <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell, row) =>(cell.nombre)
                    }
                    isKey
                >
                    PRODUCTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort
                    dataFormat={
                        (cell, row) =>(cell.codigo)
                    }
                >
                    CODIGO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataSort
                >
                    DESPACHADO
                </TableHeaderColumn>
            </Grid>
        )
    }
}



export default class HistoriaDespachoGrid extends Component{


    isExpandableRow(row) {
        if (row.detalle_despacho.length > 0) return true;
        else return false;
    }
    expandComponent(row) {
        return (
            <DetalleGrid data={ row.detalle_despacho } />
        );
    }

    render(){
        const { data, loader, listar: onPageChange, onSortChange, eliminar  } = this.props
        return(
            <div className="margin-tabgrid px-3">
                <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false} 
                    expandableRow={ this.isExpandableRow }
                    expandComponent={ this.expandComponent }
                >
                    <TableHeaderColumn
                        dataField="no_despacho"
                        dataSort
                        isKey
                    >
                        NO. DESPACHO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataSort
                        dataFormat={
                            (cell, row) => (
                                moment(cell).format("L")
                            )
                        }
                    >
                        FECHA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despachado_por"
                        dataSort
                        dataFormat = {
                            (cell, row) =>(`${cell.first_name} ${cell.last_name}`)
                        }
                    >
                        Encargado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="despachado_por"
                        dataSort
                        dataFormat = {
                            (cell, row) => {
                                return row.flujo_despacho==10 ? 'PENDIENTE' :(
                                    row.flujo_despacho==20 ? 'ACEPTADA':(
                                        row.flujo_despacho==30 ? 'RECHAZADA':(
                                            row.flujo_despacho==40 ? 'ENVIADA A SUCURSAL':''
                                        )
                                    )
                                )
                            }
                        }
                    >
                        Estado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataSort
                        dataField="no_despacho"
                        tdStyle={{ whiteSpace: 'normal' }}
                        dataFormat={(cell, row) => {
                            let showButtons = {
                                adicional: this.props.botonDespachoPDF,
                            };

                            if (row.factura_despacho.factura && (row.factura_despacho.estado_factura == 'ACEPTADA' || row.factura_despacho.estado_factura == '1')) {
                                showButtons.adicional2 = this.props.botonFacturaPDF
                            }

                            return standardActions(showButtons)(cell,row)
                        }}
                    >
                        Despacho-Factura
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="detalle_despacho"
                        dataSort
                        dataFormat={
                            (cell, row) =>{
                            return(<label>{cell.producto.nombre}</label> )
                            }
                        }
                    >
                        PRODUCTO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="detalle_despacho"
                        dataSort
                        dataFormat={
                            (cell, row)=> (cell.cantidad)
                        } 
                    >
                        CANTIDAD DESPACHADA
                    </TableHeaderColumn> */}
                </Grid>

            </div>
        )
    }

}