import React, { Component, Fragment } from 'react'
import { Redirect } from "react-router-dom";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { Monedas, getsimbolo } from "../../../../../utility/constants";
import { standardActions } from "../../../Utils/Grid/StandardActions";

import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import { T_MOVIMIENTOS } from '../../../../../utility/constants'
import ToolbarFiltroFecha from '../../../Utils/Toolbar/ToolbarFiltroFecha';

import Modal from 'react-responsive-modal';
import AnulacionForm from '../../Transacciones/Listar/anulacionForm';
import Swal from 'sweetalert2';

import ListadoT from './listadoT';
import { GridNotasCredito } from '../../NotasCredito';
// formulario de pago
import PagoCreditoForm from '../../OrdenVenta/Detalle/Pagos/PagoCreditoForm';
import ReintegroForm from './ReintegroForm';
import ConvertirChequeForm from './covertirCheque'
import DescuentoForm from './DescuentoForm';
const ver_icono = require('../../../../../../assets/img/icons/Ver.png');
const chequeAefectivo = require('../../../../../../assets/img/icons/chequeAefectivo.png');

function cellTachado(cell, row) {
    if (!row.estado) {
        return { textDecoration: "line-through", whiteSpace: 'normal', padding: 5 }
    }
    return { whiteSpace: 'normal', padding: 5 }
}


export default class DeudaReporte extends Component {
    state = {
        clipro: false,
        es_pago: false,
        es_cuenta: true,
        id_transaccion: null,
        transaccion_sel: null,
        anular_transaccion: true,
        modalNota: false,
        tab: "1",
        modalChequeEfectivo:false,
    }
    componentDidMount() {
        if (this.props.match.path == "/reporte_deuda/:id") {
            this.props.listarDetalleDeudaEmpresas(
                this.props.page,
                this.props.match.params.id
            )
            this.setState({ clipro: false })
        } else {
            this.props.listarDetalleDeudaClienteProveedor(
                this.props.page,
                this.props.match.params.id
            )
            this.setState({ clipro: true })
            this.props.selectCuentas()
            this.props.selectEmpresas();
            this.props.getTipoGastos();
        }
    }
    onChangePage = (pagina) => {
        if (this.props.match.path == "/reporte_deuda/:id") {
            this.props.listarDetalleDeudaEmpresas(
                pagina,
                this.props.match.params.id
            )
            this.setState({ clipro: false })
        } else {
            this.props.listarDetalleDeudaClienteProveedor(
                pagina,
                this.props.match.params.id
            )
            this.setState({ clipro: true })
        }
    }
    onSubmit = (values) => {
        const { orden } = this.props;
        values.id = this.state.id_transaccion;
        Swal.fire({
            title: '¿Desea anular la transacción?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anularTransaccion(values, this.props.match.params.id);
            }
        });
    }

    botonModal = (id, row) => {
        if (row.estado) {
            return (<div></div>)
        }
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        id_transaccion: id,
                        transaccion_sel: row
                    })
                    this.props.setModal(true);
                }}
            >
                <img
                    className="action-img"
                    title="adicional"
                    src={ver_icono} alt="Ver eliminado" />
            </a>
        )
    }  

    botonChequeEfectivo = (id, row) => {
        
        if (row.transaccion.tipo_documento != 2 || row.flotante == false) {
            return (<div></div>)
        }
        if (row.transaccion.cobrador == this.props.usuario && row.estado){
            return (
                <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                    onClick={(e) => {
                        e.preventDefault();
                        // this.setState({
                        //     id_transaccion: id,
                        //     transaccion_sel: row
                        // })
                        console.log(row);
                        this.setState({
                            modalChequeEfectivo: true,
                            id_transaccion:row.id,
                            transaccion_sel:row,
                        });
                    }}
                >
                    <img
                        // className="action-img"
                        style={{width:"24px", height:"24px"}}
                        title="Cobrar cheque"
                        src={chequeAefectivo} alt="Ver eliminado" />
                </a>
            )
        }
    }
    openModalNota = () => {
        console.log("open modal")
        this.setState({tab: "2"});
        this.setState({modalNota: true});
    }

    cerrarModal = () => {
        this.props.setModal(false);
        this.props.setModalDetalle(false);
        this.props.setOpenModalAlerta(false);
        this.props.setModalReintegros(false)
        this.props.setModalPagos(false)
        this.props.setModalDescuentos(false)
        this.setState({modalNota: false, modalChequeEfectivo:false});
    }
    renderModalChequeEfectivo = () => {
        const { open_modal_pagos, cuentas, simbolo, orden, data } = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;
        return (
            <Fragment>
                <Modal open={this.state.modalChequeEfectivo} onClose={(e) => {
                    this.cerrarModal(false)
                }} center>
                    <ConvertirChequeForm
                        titulo_modal="COBRO DE CHEQUE"
                        initialValues={{
                            tipo_cuenta:"false",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            monto: this.state.transaccion_sel ? this.state.transaccion_sel.transaccion.monto: 0,
                            descripcion: this.state.transaccion_sel ? this.state.transaccion_sel.transaccion.descripcion: ""
                        }}
                        cerrarModal={this.cerrarModal}
                        onSubmit={(values) => {
                            this.props.abonarEfectivoCheque(this.props.match.params.id, true, this.state.id_transaccion);
                            this.cerrarModal(false)

                        }} 
                    />
                </Modal>

            </Fragment>
        )
    }
    renderModalPagoVenta = () => {
        const { open_modal_pagos, cuentas, simbolo, orden, data } = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;
        let saldo_cuenta=data.saldo_cuenta
        const es_Descuento = "DESCUENTO"
        return (
            <Fragment>
                <Modal open={open_modal_pagos} onClose={(e) => {
                    this.props.setModalPagos(false)
                }} center>
                    <div className="mt-0">
                        <h2 className="text-center text-primary">{data.activar_boton ? 'PAGO' : 'COBRO'}</h2>
                    </div>
                    <PagoCreditoForm
                        cerrarModal={this.cerrarModal}
                        cuentas={cuentas}
                        simbolo={simbolo}
                        initialValues={{
                            tipo_cuenta: "true",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            fecha_cobro: new Date(),
                            cuota: 1,
                        }}
                        es_gasto={true}
                        texto_boton_movimiento={"AGREGAR COBRO"}
                        titulo_modal="INGRESAR COBRO A"
                        pago_cobro={'COBRO'}
                        esCobro={true}  
                        saldo_cuenta={saldo_cuenta}         
                        idClienteProveedor={this.props.idCliPro}
                        onSubmit={(e) => {
                            if(!this.props.loader) this.props.abonarGastoOPago(this.props.match.params.id, true);
                        }} />
                </Modal>

            </Fragment>
        )
    }

    renderModalDescuento = () => {
        const { open_modal_descuentos, cuentas, simbolo, orden, data} = this.props;
        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;
        console.log("nuestra data:", data)
        return (
            <Fragment>
                <Modal open={open_modal_descuentos} onClose={(e) => {
                    this.props.setModalDescuentos(false)
                }} center>
                    <div className="mt-0">
                        <h2 className="text-center text-primary">{data.activar_boton ? 'DESCUENTO' : 'COBRO'}</h2>
                    </div>
                    <DescuentoForm
                        cerrarModal={this.cerrarModal}
                        cuentas={cuentas}
                        simbolo={simbolo}
                        initialValues={{
                            tipo_cuenta: "false",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            fecha_cobro: new Date()
                        }}
                        es_gasto={true}
                        texto_boton_movimiento={"AGREGAR DESCUENTO"}
                        titulo_modal="DESCUENTOS"
                        esCobro={true}
                        idCliente = {data.idCliPro}
                        onSubmit={(e) => {
                            if(!this.props.loader) this.props.agregarDescuento(this.props.match.params.id, true);
                        }} />
                </Modal>
            </Fragment>
        )
    }
    
    botonAlerta = (id, row) => {
        if (row.estado) {
            return (<div></div>)
        }
        return (<img className="action-img" title="adicional6" src={require("../../../../../../assets/img/icons/alarm.png")}
            onClick={(e) => {
                e.preventDefault();
                this.setState({
                    id_transaccion: id,
                    transaccion_sel: row,
                    anular_transaccion: true
                })
                this.props.setOpenModalAlerta(true);
            }}
            alt="Ver eliminado" />)
    }

    abrirModalAlerta = (id, row) => {
        console.log("hola a todos")
        this.setState({
            id_transaccion: id,
            transaccion_sel: row,
            anular_transaccion: true
        })
        this.props.setOpenModalAlerta(true);
    }

    renderModalAlerta = () => {
        const { open_modal_alerta } = this.props;

        return (
            <Modal open={open_modal_alerta} onClose={() => { this.props.setOpenModalAlerta(false) }} center>
               < div className="row">
                    <div className={`col-lg-4 col-md-6 col-sm-8 offset-lg-4 offset-md-3 offset-sm-2 text-center`}>
                        <div className="panel-body">
                        <img src={require("../../../../../../assets/img/alertas/notificacion1fcm.png")} alt="Prenda Cŕedito Avanza"/>
                        </div>
                    </div>
                </div>
                <div className="text-center txt">
                    <span className="reset-caption">El despacho no cuenta con una factura asociada.</span><br/>

                </div>              
            </Modal>
        );
    }

    renderModalAnulacion = () => {
        const { open_modal } = this.props;
        return (
            <Modal open={open_modal} onClose={(e) => {
                this.props.setModal(false)
            }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">JUSTIFICACIÓN DE ANULACIÓN</h2>
                </div>
                {
                    (this.state.transaccion_sel && !this.state.transaccion_sel.transaccion.estado) ? (
                        <div className="form-validate mb-lg text-center" >
                            <span className="m-none t-mostaza semibold">Fecha anulación: </span><br />
                            <span className="m-none t-musgo semibold"> <RenderDateTime fecha={this.state.transaccion_sel.transaccion.fecha_anulacion} /> </span><br />
                            <span className="m-none t-mostaza semibold">Usuario anulación: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.usuario_anulacion}`}</span><br />
                            <span className="m-none t-mostaza semibold">descripción: </span><br />
                            <span className="m-none t-musgo semibold">{`${this.state.transaccion_sel.transaccion.descripcionAnulacion}`}</span><br />
                        </div>
                    ) : (
                            <AnulacionForm
                                onSubmit={this.onSubmit}
                                cerrarModal={this.props.setModal}
                            />
                        )
                }

            </Modal>
        )
    }

    botonModalDetalle = (id, row) => {
        if (row.estado) {
            return (<div></div>)
        }
        return (
            <a className="px-2" style={{ cursor: "pointer", color: "#c4183c" }}
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        id_transaccion: id,
                        transaccion_sel: row
                    })
                    this.props.setModalDetalle(true);
                }}
            >
                <img
                    className="action-img"
                    title="adicional2"
                    src={ver_icono} alt="Ver eliminado" />
            </a>
        )
    }

    renderModalDetalle = () => {
        const { open_modal_ver } = this.props;
        const { transaccion_sel } = this.state;
    
        // Verificamos que exista la propiedad abonos_relacionados en transaccion_sel
        const abonosRelacionados = transaccion_sel&&transaccion_sel.abonos_relacionados || [];
    
        return (
            <Modal open={open_modal_ver} onClose={() => { this.props.setModalDetalle(false) }} center>
                <div className="mt-5">
                    <h2 className="text-center text-primary">Detalle del Pago</h2>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>No. Factura</th>
                            <th>No. Despacho</th>
                            <th>Monto Abonado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {abonosRelacionados.map(abono => (
                            <tr key={abono.id}>
                                <td>{abono.no_factura_rel}</td>
                                <td>{abono.no_despacho_rel}</td>
                                <td>Q   {abono.monto_abonado.toLocaleString('es-GT')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal>
        );
    }

    abrirModalPagos= (id, row) => {
        console.log("abrirModalPagos ejecutado con id:", id, "y row:", row);
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setModalDetalle(true,row)
    }

    abrirModalEliminar = (id, row) => {       
        this.setState({
            id_transaccion: id,
            transaccion_sel: row
        })
        this.props.setModal(true)
    }

    botonFactura = (id, row) => {
        if(!row.transaccion.dte){
            return(<div></div>)
        }
        return (
            <div className="">
                {/* <span className="font-weight-bold">PDF:</span> */}
                <a className="px-2 t-musgo" style={{cursor: "pointer"}}
                    target="_blank"
                    href={row.transaccion.dte.factura}>
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            </div>
        )
    }

    botonOrdenPDF = (id, row) => {
        return (
            <div className="">
                {/* <span className="font-weight-bold">PDF:</span> */}
                <a className="px-2 t-musgo" style={{cursor: "pointer"}}
                    target="_blank"
                    href={row.transaccion.despacho.orden.documento}>
                    <img
                        className="action-img"
                        title="PDF"
                        src={require("../../../../../../assets/img/icons/facturapdf.png")} alt="PDF"/>
                </a>
            </div>
        )
    }

    getMovimiento = (val) => {
        let texto = ""
        T_MOVIMIENTOS.forEach((item, index) => {
            if (item.value == val) {
                texto = item.label
            }
        })
        return texto
    }
    handleDateStart = (value) => {
        this.props.setDateStart(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        )
    }

    handleDateEnd = (value) => {
        this.props.setDateEnd(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        )
    }
    handleEstado = (value) => {
        this.props.estadoChange(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        )
    }
    handleCliente = (value) => {
        this.props.changeCliente1(
            value,
            this.props.match.params.id
        )
    }
    handleProveedor = (value) => {
        this.props.changeProveedor(
            value,
            this.props.match.params.id
        )
    }
    handleTipoGasto = (value) => {
        this.props.changeTipoGasto(
            value,
            this.props.match.params.id
        )
    }
    handleFormaPago = (value) =>{
        this.props.formaPagoChange(
            value,
            this.props.match.params.id
        )
    }
    handleTipoMovimiento = (value) =>{
        this.props.tipoMovimientoChange(
            value,
            this.state.clipro ? 1 : 2,
            this.props.match.params.id
        )
    }
    renderModalreintegros = () => {
        const { open_modal_reintegros, cuentas, simbolo, orden, data, empresas } = this.props;

        let no_cuenta = cuentas.length ? cuentas[0].id : null;
        let es_cobro = data.activar_boton;

        return (
            <Fragment>
                <Modal open={open_modal_reintegros} onClose={(e) => {
                    this.props.setModalReintegros(false)
                }} center>
                    <div className="mt-0">
                        <h2 className="text-center text-primary">{data.activar_boton ? 'REINTEGRO' : ''}</h2>
                    </div>
                    <ReintegroForm
                        empresas={empresas}
                        cambioDescuento={this.props.cambioDescuento}
                        cerrarModal={this.cerrarModal}
                        cuentas={cuentas}
                        simbolo={simbolo}
                        initialValues={{
                            tipo_cuenta: "true",
                            fecha: new Date(),
                            numero_cuenta: no_cuenta,
                            fecha_cobro: new Date()
                        }}
                        tipo_gastos={this.props.tipo_gastos}
                        es_gasto={true}
                        texto_boton_movimiento={"AGREGAR COBRO"}
                        titulo_modal="INGRESAR COBRO A"
                        pago_cobro={'COBRO'}
                        esCobro={true}
                        onSubmit={(e) => {
                            // e.cuenta_cliente =  this.props.match.params.id;
                            console.log(e)
                            if(!this.props.loader) this.props.reintegroCliente(this.props.match.params.id, false);
                        }} />
                </Modal>

            </Fragment>
        )
    }
    render() {
        const { onPageChange, data, loader, open_modal,open_modal_ver,open_modal_alerta,permisos, page, nombre_empresa, moneda, idCliente } = this.props;
        if (permisos["deuda"] === false && permisos['cuentasc'] === false && permisos['cuentasp'] === false) {
            return <Redirect to={`/`} />
        }
        return (
            <CardSimple
                titulo={`BALANCE: ${this.props.nombre_empresa} e Industria AYG`}
                margen={""}
            >
                <LoadMask loading={loader} light type={"TailSpin"}>
                    {this.renderModalAnulacion()}
                    {this.renderModalDetalle()}
                    {this.renderModalAlerta()}
                    {this.renderModalDescuento()}
                    {this.renderModalPagoVenta()}
                    {this.renderModalreintegros()}
                    {this.renderModalChequeEfectivo()}
                    <ToolbarFiltroFecha
                        {...this.props}
                        setDateStart={this.handleDateStart}
                        setDateEnd={this.handleDateEnd}
                        dateStart={this.props.dateStart}
                        dateEnd={this.props.dateEnd}
                        justify={"justify-content-center justify-content-sm-start"}
                        setEstado={true}
                        valorEstado = {this.props.estado}
                        changeEstado = {this.handleEstado}
                        esBalanceEmpresa = {data.activar_boton | this.state.clipro ? false: true}
                        esBalanceCliente = {this.state.clipro ? true: false}
                        cliente={this.props.cliente1}
                        changeCliente={this.handleCliente}
                        proveedor = {this.props.proveedor}
                        changeProveedor = {this.handleProveedor}
                        tipo_gasto = {this.props.tipo_gasto}
                        changeTipoGasto = {this.handleTipoGasto}
                        valorFormaPago = {this.props.forma_pago}
                        changeFormaPago = {this.handleFormaPago}
                        valorTipoMovimiento = {this.props.tipo_movimiento}
                        changeTipoMovimiento = {this.handleTipoMovimiento}
                    />
                    <div className="col-md-12 pt-3 d-flex justify-content-end">
                        {
                            (data.activar_boton) && (
                                <div>
                                    <button
                                        onClick={() => {
                                            this.props.setModalDescuentos(true);
                                        }}
                                        className="btn btn-primary text-uppercase mr-1">
                                        Agregar descuento
                                    </button>
                                    <button
                                        onClick={() => {
                                            this.props.setModalReintegros(true);
                                        }}
                                        className="btn btn-secondary text-uppercase mr-1">
                                        Agregar reintegro
                                    </button>
                                    <button
                                        onClick={() => {
                                            this.props.setModalPagos(true);
                                        }}
                                        className="btn btn-primary text-uppercase mr-1">
                                        Agregar pago
                                    </button>
                                    <button
                                        onClick={() => {
                                            // this.props.setModalPagos(true);
                                            this.props.ImprimirBalance(this.props.match.params.id)
                                        }}
                                        className="btn btn-mostaza text-uppercase">
                                        imprimir balance
                                    </button>
                                </div>
                            )
                        }
                        {
                            (this.state.clipro == true && !data.activar_boton) && (
                                <div>
                                    <button
                                        onClick={this.openModalNota}
                                        className="btn btn-primary text-uppercase">
                                        NOTA DE CRÉDITO
                                    </button>
                                </div>
                            )
                        }

                    </div>
                    {
                        (data.activar_boton | this.state.clipro) ?
                        (<div className="col-md-12 pt-3 d-flex justify-content-end">
                                <div className="col-md-auto">
                                    <span className="t-musgo font-weight-bold">
                                        TOTAL DEBE
                                    </span>
                                    <h4 className="t-azul m-none">{ <RenderMoneda monto={this.props.total_debe} simbolo={getsimbolo(this.props.moneda)} />}</h4>
                                </div>
                                <div className="col-md-auto">
                                        <span className="t-musgo font-weight-bold">
                                            TOTAL HABER
                                        </span>
                                        <h4 className="t-primary m-none">{ <RenderMoneda monto={this.props.total_haber} simbolo={getsimbolo(this.props.moneda)} />}</h4>
                                </div>
                                <div className="col-md-auto">
                                        <span className="t-musgo font-weight-bold">
                                            TOTAL FLOTANTE
                                        </span>
                                        <h4 className="t-primary m-none">{ <RenderMoneda monto={this.props.total_flotante} simbolo={getsimbolo(this.props.moneda)} />}</h4>
                                </div>
                    </div>)
                    :
                    <div className="col-12 m-0 p-0 mt-3" style={{border:"1px solid #E8E8E8", }}>
                    <div className="col-12 row m-0 p-0">
                        <div className="px-3 py-1">
                            <h5>TOTAL</h5>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Debe</h5>
                                <h3 className="t-mostaza text-left"  htmlFor="descripcion">{
                                        <RenderMoneda monto={this.props.total_debe} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                        <div className="col-md-5 my-3 d-flex justify-content-center">
                            <div className="col-md-8">
                                <h5>Haber</h5>
                                <h3 className="t-primary"  htmlFor="descripcion">{
                                        <RenderMoneda monto={this.props.total_haber} simbolo={'Q'} />
                                }</h3>
                            </div>
                        </div>
                    </div>
                    </div>
                    }
                    <div className="mt-5">
                        {
                            (this.state.clipro == true && !data.activar_boton) ? (
                                <Tabs
                                    defaultActiveKey={this.state.tab}
                                    activeKey={this.state.tab}
                                    tabBarPoition="top"
                                    onChange={(step) => { this.setState({tab: step})}}
                                    renderTabBar={() => <ScrollableInkTabBar />}
                                    renderTabContent={() => <TabContent />}
                                >
                                    <TabPane tab="HISTORIAL" key="1">
                                        <LoadMask loading={loader} light type={"TailSpin"}>
                                            <ListadoT
                                                {...this.props}
                                                onChangePage={this.onChangePage}
                                                getMovimiento={this.getMovimiento}
                                                abrirModalEliminar={this.abrirModalEliminar}
                                                botonModal={this.botonModal}
                                            />
                                        </LoadMask>
                                    </TabPane>
                                    <TabPane tab="NOTAS DE CRÉDITO " key="2">
                                        <GridNotasCredito
                                            openModal={this.state.modalNota}
                                            closeModal={this.cerrarModal}
                                            idP={this.props.idCliPro}
                                            idD={null}
                                        />
                                    </TabPane>
                                </Tabs>

                            ) : (
                                <ListadoT
                                    {...this.props}
                                    onChangePage={this.onChangePage}
                                    getMovimiento={this.getMovimiento}
                                    abrirModalEliminar={this.abrirModalEliminar}
                                    botonFactura={this.botonFactura}
                                    botonOrdenPDF={this.botonOrdenPDF}
                                    abrirModalPagos={this.abrirModalPagos}
                                    botonModal={this.botonModal}
                                    abrirModalAlerta={this.abrirModalAlerta}
                                    botonAlerta={this.botonAlerta}
                                    botonModalDetalle={this.botonModalDetalle}
                                    botonChequeEfectivo={this.botonChequeEfectivo}
                                />

                            )
                        }
                    </div>
                </LoadMask>
            </CardSimple>
        );
    }
}
