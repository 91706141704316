import React, { Component, Fragment } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Link, Redirect } from 'react-router-dom';
import CuotaForm from '../Cuota/cuotaForm';
import { RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";
import { cuotasGeneralOptions } from '../../../../../utility/constants';
import { renderField, AsyncSelectField, renderSelectField, renderCurrency, renderTextArea, SelectField} from '../../../Utils/renderField';
import Switch from "react-switch";
import { api } from 'api';


class ItemArray extends Component {
    state = {
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
        }
    }
    componentDidUpdate(prevProps, prevState) {
    }


    render() {
        const { index, cuota, simbolo, eliminar, cuotasOptions } = this.props;

        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${cuota}.cuota`}
                        label="cuota"
                        valueKey="value"
                        labelKey="label"
                        options={cuotasOptions}
                        component={SelectField}
                    />
                </td>
                <td className="sin-borde-topzz">
                    <Field
                        name={`${cuota}.porcentaje_comision`}
                        label="porcentaje_comision"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
            </tr>
        );
    }
}


const renderCuotas = ({ fields, meta: { error, submitFailed }, simbolo }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "12%" }}>Cuota</th>
                            <th style={{ width: "12%" }}>Porcentaje comisión</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((cuota, index) => {
                            let cuotasEnUso = fields.getAll();
                            const cuentaActual = fields.get(index);

                            let cuotasOptions = []
                            _.forEach(cuotasGeneralOptions, cuotaOpcion => {
                                let encontrado = _.find(cuotasEnUso, {cuota: cuotaOpcion.value})
                                if (!encontrado) cuotasOptions.push(cuotaOpcion);
                            });
                            let temp = _.find(cuotasGeneralOptions, {value: cuentaActual.cuota})
                            if (temp) cuotasOptions.unshift(temp)


                            return (
                                <ItemArray
                                    index={index}
                                    key={index}
                                    cuotasOptions={cuotasOptions}
                                    cuota={cuota}
                                    simbolo={simbolo}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                    field={fields ? fields.get(index) : null}
                                />
                            );

                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({porcentaje_comision: 0})}>
                    AGREGAR CUOTA
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)

const PossForm = (props) => {
    const { handleSubmit, cuentas, onChangeSwichAplicarCobro, aplicarCobro } = props;
    const reRender = () => {
    }


    return (
        <form name="PossForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="form-group has-feedback col-6">
                    <label className="t-azul" htmlFor="nombre">Nombre</label>
                    <Field name="nombre" label="nombre" component={renderField} type="text" className="form-control" />
                </div>
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="cuenta">No. de Cuenta</label>
                            <Field
                                name="cuenta"
                                label="cuenta"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                className="form-control"
                            />
                        </div>
            </div>
            <div className="row justify-content-center">
                <div className="form-group has-feedback  col-sm-12  col-12">
                    <label className="t-azul" htmlFor="descripcion">Descripción</label>
                    <Field
                        name="descripcion"
                        label="descripcion"
                        component={renderTextArea}
                        type="text"
                        className="form-control" />
                </div>
            </div>

            <div className="row justify-content-start">
                <div className="col-md-6 m-0 p-0">
                    <div style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                        <FieldArray
                            name="cuotas"
                            component={renderCuotas}
                            reRender={reRender}
                            simbolo={'% '}/>
                    </div>
                </div>
                <div className="col-md-6 m-0 p-0" >
                    <div style={{padding:"15px"}}>
                        <div>
                            <label className="t-azul" htmlFor="cuenta">Retención ISR</label>
                            <Field
                                name="porcentaje_retencion_ISR"
                                label="porcentaje_retencion_ISR"
                                component={renderCurrency}
                                prefix={'% '}
                                type="number"
                                className="form-control"/>
                        </div>
                        <div className="d-flex justify-content-md-start justify-content-center" style={{padding:"15px"}}>
                            <Switch
                                id="normal-switch"
                                onColor="#0a5995"
                                offColor="#ccc"
                                onChange={(value) => {
                                    onChangeSwichAplicarCobro(value)
                                }}
                                checked={aplicarCobro}
                            />
                            <label className="t-azul" htmlFor="cuenta">&nbsp;&nbsp;Aplica cobro por primera transacción</label>
                        </div>
                        <div>
                            <label className="t-azul" htmlFor="cuenta">Monto por primera transacción</label>
                            <Field
                                name="monto_primera_transaccion"
                                label="monto_primera_transaccion"
                                component={renderCurrency}
                                type="number"
                                disabled={!aplicarCobro}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                        <Link className="btn btn-secondary  align-self-center" to="/poss" >CANCELAR</Link>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">GUARDAR</button>
                    </div>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'PossForm', // a unique identifier for this form
    validate: (data, props) => {
        const errors = {};

        if (!data.nombre) errors.nombre = 'Campo requerido';

        if (data.cuotas && data.cuotas.length > 0) {
            const cuotasArray = [];
            data.cuotas.forEach(function (cuota, index) {
                let cuotasErrors = {};

                let repetidos = _.filter(data.cuotas, {cuota: cuota.cuota})
                if (repetidos.length > 1) cuotasErrors.cuota = "Cuota repetida"

                if (cuotasErrors) cuotasArray[index] = cuotasErrors;
            });
            if (cuotasArray.length) errors.cuotas = cuotasArray;
        }
        return errors;
    },
})(PossForm);
