import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import { renderField, renderTextArea, renderSelectField, renderCurrency, AsyncSelectField, SelectField } from '../../../Utils/renderField';
import { Link, Redirect } from 'react-router-dom';
import { departamentos, Monedas } from '../../../../../utility/constants';
import { RenderMoneda, getSimbolo } from "../../../Utils/renderField/renderTableField";
import { renderDropdownDateField } from "../../../Utils/renderField/otherrenders";
import Modal from 'react-responsive-modal';
import ClienteForm from './ClienteForm';

import { api } from 'api';

class ItemArray extends Component {
    state = {
        producto: {},
        sel_producto: {}
    }
    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.textInput = React.createRef();
        this.state = {
            producto: {},
            loadOptions: (search) => props.getProductos(search)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.simbolo !== prevProps.simbolo) {
            if (this.textInput && this.textInput.current) {
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                })
                this.forceUpdate();
                // this.textInput.ref.props.loadOptions()
            }
        }
        if (this.props.proveedor !== prevProps.proveedor) {
            if (this.textInput && this.textInput.current) {
                this.textInput.current.ref.props.loadOptions('');
                this.setState({
                    loadOptions: (search) => this.props.getProductos(search)
                })
                this.forceUpdate();
                // this.textInput.ref.props.loadOptions()
            }
        }
        if (this.props.prod_seleccionado !== prevProps.prod_seleccionado) {
            const { todos, index } = this.props;
            if (this.props.prod_seleccionado)
                this.setState({ producto: this.props.prod_seleccionado.producto });
        }
    }
    setPrecioUnitario = (producto_nuevo) => {
        const { todos, index } = this.props;
        if (producto_nuevo !== undefined && producto_nuevo.precio_ultimo !== undefined) {
            if (producto_nuevo.precio_ultimo != null && producto_nuevo.precio_ultimo != undefined) {
                todos[index]['precio_unitario'] = producto_nuevo.precio_ultimo;
                todos[index]['descuento'] = 0;
            }
            else {
                todos[index]['precio_unitario'] = 0;
                todos[index]['descuento'] = 0;
            }
        }

    }
    render() {
        const { index, producto, simbolo, eliminar, mostrar_precios, subtotal, proveedor, prod_seleccionado } = this.props;
        return (
            <tr key={index} style={{ height: "68px" }}>
                <td className="text-center sin-borde-top" style={{ width: "48px" }}>
                    <img className="action-img" src={require('assets/img/icons/Cerrar.png')} alt="Borrar"
                        title="Borrar"
                        onClick={() => {
                            eliminar()
                        }}
                    />
                </td>
                <td className="sin-borde-top">
                    <Field
                        withRef
                        key={`${producto}-${simbolo}-${proveedor}`}
                        ref={this.textInput}
                        id={`${producto}.selectProducto`}
                        name={`${producto}.producto`}
                        label="nombre"
                        component={AsyncSelectField}
                        loadOptions={this.state.loadOptions}
                        top={{ top: "67px", position: "inherit" }}
                        type="text"
                        valueKey="id"
                        labelKey="nombre"
                        onChange={(e) => {
                            this.setState({ producto: e });
                            this.setPrecioUnitario(e);
                        }}
                        className="form-control" />
                    {
                        (this.state.producto && this.state.producto.menor_precio) && (
                            <div className="speech-bubble mt-1 p-2">
                                <span> {this.state.producto.menor_precio}</span>
                            </div>
                        )
                    }

                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.cantidad`}
                        type="number"
                        component={renderField}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
                <td className="sin-borde-topzz">
                    {
                        mostrar_precios ? (
                            <div>
                                <Field
                                    name={`${producto}.precio_unitario`}
                                    label="tipo_cambio"
                                    prefix={simbolo}
                                    component={renderCurrency}
                                    parse={cell => parseFloat(cell)}
                                    className="form-control"
                                    top={{ top: "67px", position: "inherit" }}
                                />
                                {
                                    (prod_seleccionado.producto && prod_seleccionado.precio_unitario) ?
                                    (prod_seleccionado.producto.precio_venta > prod_seleccionado.precio_unitario) && (
                                        <div className="speech-bubble mt-1 p-2">
                                            <span style={{color: "#ad1717"}}>Precio menor al de venta (Fabrica)</span>
                                        </div>
                                    ) : (<div></div>)
                                }
                            </div>

                        ) : (
                                <Fragment>
                                    {
                                        prod_seleccionado.precio_unitario == 0 ? (
                                            <RenderMoneda monto={0} simbolo={simbolo} />
                                        ) : (
                                                <RenderMoneda monto={prod_seleccionado.precio_unitario} simbolo={simbolo} />
                                            )
                                    }
                                </Fragment>
                            )
                    }

                </td>
                <td className="sin-borde-top" style={{ width: "10%" }}>
                    <RenderMoneda monto={subtotal} simbolo={simbolo} />

                </td>
                <td className="sin-borde-top">
                    <Field
                        name={`${producto}.descuento`}
                        type="number"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        top={{ top: "67px", position: "inherit" }}
                    />
                </td>
            </tr>
        );
    }
}


const renderProductos = ({ fields, meta: { error, submitFailed }, simbolo, getProductos, mostrar_precios, productos, proveedor }) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="col-sm-12 form-group np-r  mt-2 sin-borde p-0 color-header-tabla">
                <table className="table table-sm table-responsive-sm m-0 table-striped">
                    <thead>
                        <tr>
                            <th ></th>
                            <th>Producto</th>
                            <th style={{ width: "12%" }}>Cantidad</th>
                            <th style={{ width: "12%" }}>Costo unitario</th>
                            <th style={{ width: "12%" }}>Subtotal</th>
                            <th style={{ width: "12%" }}>(-) Descuento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((producto, index) => {
                            let subtotal = 0;
                            let mensaje;
                            let todos = fields.getAll();
                            const prod = fields.get(index);
                            try {
                                subtotal = Number(prod.cantidad) * Number(prod.precio_unitario)
                                subtotal = Number(subtotal).toFixed(2)
                            } catch (error) {
                                subtotal = 0;
                            }


                            return (
                                <ItemArray
                                    mostrar_precios={mostrar_precios}
                                    prod_seleccionado={prod}
                                    proveedor={proveedor}
                                    index={index}
                                    key={index}
                                    todos={todos}
                                    subtotal={subtotal}
                                    producto={producto}
                                    getProductos={getProductos}
                                    simbolo={simbolo}
                                    eliminar={() => {
                                        fields.remove(index)
                                    }}
                                    field={fields ? fields.get(index) : null}
                                />
                            );

                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({ cantidad: 1 })}>
                    AGREGAR PRODUCTO
                </button>
            </div>
        </div>
        {submitFailed &&
            error &&
            <div className="invalid-feedback-array text-danger">
                {error}
            </div>}
    </div>
)
//fin de render de productos

class PeticionVentaForm extends Component {
    state = {
        proveedor: null,
        moneda: null,
        info_cliente: null
    }
    componentDidMount() {
        this.props.selectRegiones()
    }

    getProductos = (search) => {
        let params = { es_peticion: true }
        if (search) {
            params.search = search;
        }
        if (this.props.proveedor) {
            params.proveedor = this.props.proveedor;
        }
        return api.get("productos/getProductosConPrecioV", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    };

    getClientes = (search) => {
    const params = {}
    params.search = search
    params.tipo = 2
    params.esCliente = 1
    if(this.props.permisos.visualizar_mis_datos){
        params.visualizar_mis_datos = true
        params.id_user = this.props.id_user
    }
    return api.get("clientes", params).
        then((data) => {
            if (data){
                return [...data.results];
            }
            return [];
        }).catch(() => {
            return [];
        });
    };
    reRender = () => {
        this.setState({
            proveedor: this.props.producto,
            moneda: this.props.moneda
        });
    }
    handleSubmit = (values) =>{
        values.tipo = 2
        this.props.crearCliente(values)
    }
    render() {
        const { handleSubmit,
            total,
            simbolo,
            mostrar_precios,
            empresas,
            texto_descripcion,
            departamento, municipio,
            setDepartamento, setMunicipio,
            subtotal, handleCuentas, empresa, productos, tiene_iva, validateNitForm, AddMunicipio, ChangeMunicipio, municipios_bancos, open_modal, setOpenModal, permisos,
            changeFieldClienteForm,
            setDataName,
            getFieldClienteFormFacturacion,} = this.props;

        return (
            
            <form name="PeticionVentaForm" className="form-validate mb-lg " onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <h4 className="t-primary" >{texto_descripcion}</h4>
                </div>
                <Modal open={open_modal} onClose={(e) => {
                    setOpenModal(false)
                }} center>
                    <div className="">
                        <ClienteForm
                            getMunicipios={this.props.setMunicipios}
                            municipios={this.props.municipios}
                            regiones={this.props.regiones}
                            onSubmit={this.handleSubmit}
                            titulo={"REGISTRAR CLIENTE"}
                            es_cliente={2}
                            validateNit={validateNitForm}
                            AddMunicipio={AddMunicipio}
                            ChangeMunicipio={ChangeMunicipio}
                            municipios_bancos={municipios_bancos}
                            departamento={departamento}
                            municipio={municipio}
                            setDepartamento={setDepartamento}
                            setMunicipio={setMunicipio}
                            EliminarMunicipio={this.props.EliminarMunicipio}
                            changeFieldClienteForm={changeFieldClienteForm}
                            setDataName={setDataName}
                            initialValues={{
                                facturacion_ref: []
                            }}
                            getFieldClienteFormFacturacion={getFieldClienteFormFacturacion}

                        />
                    </div>
                </Modal>
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 row m-0 p-0">
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Fecha</label>
                                <Field
                                    name="fecha"
                                    selectedDate={new Date()}
                                    maxDate="2030-12-31"
                                    minDate="2018-01-01"
                                    className="form-control"
                                    component={renderDropdownDateField}
                                />
                            </div>
                            {/* <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="empresa">Empresa</label>
                                <Field
                                    name="empresa"
                                    label="empresa"
                                    labelKey="nombre" valueKey="id"
                                    component={renderSelectField}
                                    options={empresas}
                                    onChange={(e) => {
                                        handleCuentas(e.target.value, 'GTQ')
                                    }}
                                    className="form-control"
                                />
                            </div> */}
                            <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Cliente</label>
                                <div className="col-12 d-flex justify-content-around m-0 p-0">
                                    {
                                        permisos.registro_rapido_clientes ?
                                        <div className="col-10 m-0 p-0">
                                            <Field
                                                name="proveedor"
                                                label="nombre"
                                                component={AsyncSelectField}
                                                loadOptions={this.getClientes}
                                                defaultValue={this.props.ultimo_cliente}
                                                type="text"
                                                valueKey="id"
                                                labelKey="nombre"
                                                onChange={(e) => {
                                                    this.setState({ proveedor: e.id, info_cliente: e });
                                                    this.props.setProveedor(e.id);

                                                    if(e.empresa && e.empresa.length){
                                                        this.props.setTieneNits(true);
                                                    }
                                                    else{
                                                        this.props.setTieneNits(false);
                                                        e.empresa.push({Nit: "CF", nombre: "Consumidor Final", tipo: "NIT"})
                                                    }
                                                    this.props.setNits(e.empresa);
                                                }}
                                                className="form-control" />
                                            </div>
                                            :
                                            <div className="col-12 m-0 p-0">
                                            <Field
                                                name="proveedor"
                                                label="nombre"
                                                component={AsyncSelectField}
                                                loadOptions={this.getClientes}
                                                type="text"
                                                valueKey="id"
                                                labelKey="nombre"
                                                onChange={(e) => {
                                                    this.setState({ proveedor: e.id, info_cliente: e });
                                                    this.props.setProveedor(e.id);
                                                    if(e.empresa && e.empresa.length){
                                                        let tiene_cf = false
                                                        e.empresa.forEach(empresa =>{
                                                            if(empresa.Nit == "CF" | e.empresa.Nit == "C/F"){
                                                                if(empresa.nombre=="Consumidor Final"){
                                                                    tiene_cf=true
                                                                }
                                                            }
                                                        })
                                                        if(tiene_cf==false){
                                                            e.empresa.push({Nit: "CF", nombre: "Consumidor Final"})
                                                        }
                                                    }
                                                    else{
                                                        e.empresa.push({Nit: "CF", nombre: "Consumidor Final"})
                                                    }
                                                    this.props.setNits(e.empresa);
                                                }}
                                                className="form-control" />
                                            </div>
                                    }
                                    {
                                        permisos.registro_rapido_clientes &&
                                        <div className="col-2">
                                            <button type="button" className="btn btn-secondary-sm" onClick={ (e)=>{setOpenModal(true)}}>+</button>
                                        </div>
                                    }
                                    </div>
                            </div>
                            {/* <div className="form-group has-feedback col-12">
                                <label className="t-azul" htmlFor="nombre">Moneda</label>
                                <Field
                                    name="moneda"
                                    label="moneda"
                                    component={}
                                    options={Monedas}
                                    onChange={(e) => {
                                        handleCuentas(empresa, e.target.value)
                                        this.props.setMoneda(e.target.value)
                                        this.props.verificarPreciosProductos()
                                    }}
                                    className="form-control"
                                />
                            </div> */}
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="form-group has-feedback  col-sm-12  col-12">
                                <label className="t-azul" htmlFor="descripcion">Descripción</label>
                                <Field
                                    name="descripcion"
                                    label="descripcion"
                                    rows={7}
                                    component={renderTextArea}
                                    type="text"
                                    top={{ top: "174px" }}
                                    className="form-control" />
                            </div>
                            {/* <div className="form-group has-feedback col-sm-12 col-12">
                                 <Field
                                    name="tiene_iva"
                                    label="¿Desea agregar IVA?"
                                    component={renderFieldCheck}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                this.props.proveedor &&
                <div className="col-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0">
                        <FieldArray
                            mostrar_precios={mostrar_precios}
                            proveedor={this.state.proveedor}
                            productos={productos}
                            reRender={this.reRender}
                            getProductos={this.getProductos}
                            simbolo={simbolo}
                            name="detalle_orden"
                            component={renderProductos} />
                    </div>
                </div>}
                {/* <div className="col-md-12 m-0 p-0 d-flex justify-content-center">
                    <div className="col-md-10 m-0 p-0 d-flex justify-content-end">
                        <div className="col-md-4 m-0 p-0 text-right">

                            <label className="t-azul" htmlFor="descuento">(-) Descuento</label>
                            <Field
                                name="descuento"
                                label="descuento"
                                prefix={simbolo}
                                component={renderCurrency}
                                parse={cell => parseFloat(cell)}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div> */}


                <div className="col-md-12 m-0 p-0 d-flex justify-content-center mt-3">
                    <div className="col-md-10 m-0 p-0 inline-headers text-center text-md-right">
                        <span className="t-primary h5 mr-3" htmlFor="descripcion">TOTAL.     </span>
                        <h2 className="t-mostaza" htmlFor="descripcion">{
                            <RenderMoneda monto={total} simbolo={simbolo} />
                        }</h2>
                    </div>

                </div>
                <div className="col-md-12 m-0 p-0 d-flex justify-content-center mt-3">
                    <div className="col-md-10 m-0 p-0 inline-headers text-center text-md-right">
                        <span className="t-primary h5 mr-3" htmlFor="descripcion">Limite de Crédito:
                                <RenderMoneda monto={this.props.cliente ? this.props.cliente.limite_credito : 0} simbolo={simbolo} />
                        </span>
                    </div>

                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                            <Link className="btn btn-secondary  align-self-center" to="/peticionesventa" >CANCELAR</Link>
                            <button type="submit" className="btn btn-primary m-1 align-self-center">SIGUIENTE</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

//{"fecha":"2019-10-15","empresa":"1","proveedor":1,"moneda":"USD","detalle_orden":[{"producto":2,"cantidad":"3","precio_unitario":3}]}


PeticionVentaForm = reduxForm({
    form: 'PeticionVentaForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.proveedor) {
            errors.proveedor = 'Campo requerido';
        }
        // if(!data.descripcion){
        //     errors.descripcion = "Campo requerido"
        // }
        if (data.descuento) {
            let subtotal = 0;
            data.detalle_orden.forEach(x => {
                if (!isNaN(x.cantidad) && !isNaN(x.precio_unitario)) {
                    subtotal += Number(x.cantidad) * Number(x.precio_unitario);
                }
            })
            if (Number(subtotal) < Number(data.descuento)) {
                errors.descuento = "El descuento no debe de ser mayor que el subtotal"
            }
        }
        if (!data.detalle_orden || !data.detalle_orden.length) {
            errors.detalle_orden = { _error: 'Debe de ingresar al menos un producto' }
        } else {
            const detalleArray = []
            data.detalle_orden.forEach(function (producto, index) {
                let detErrors = {};
                let repetidos = [];
                let subtotal = 0;
                subtotal += producto.cantidad * producto.precio_unitario;
                if (producto.producto) {
                    repetidos = _.filter(data.detalle_orden, (x) => {
                        if (x.producto)
                            return x.producto.id == producto.producto.id
                        return false;
                    });
                }

                if (!producto.producto) {
                    detErrors.producto = "Campo requerido";
                } else {
                    if (1 < repetidos.length) {
                        detErrors.producto = "Este producto está repetido"
                    }
                }
                if (!producto.cantidad) {
                    detErrors.cantidad = "Campo requerido"
                } else {
                    if (parseFloat(producto.cantidad) <= 0) {
                        detErrors.cantidad = "Debe ser mayor a 0"
                    }
                }
                if (!producto.precio_unitario) {
                    detErrors.precio_unitario = "Campo requerido";
                } else {
                    // aqui se verifica si el usuario que esta realizando la orden de venta es un vendedor o un administrador
                    // si es un vendedor permitira modificar los precios a un menor valor del permitido
                    // si no mantendra la restriccion del menor precio
                    if (props.role === true) {
                        if (parseFloat(producto.precio_unitario) <= 0) {
                            detErrors.precio_unitario = `Debe ser mayor a 0`
                        }
                    }
                    else {
                        if (parseFloat(producto.precio_unitario) <= producto.producto.menor_preciov) {
                            detErrors.precio_unitario = `Debe ser mayor a ${producto.producto.menor_preciov}`
                        }
                    }
                }
                if (Number(subtotal) < Number(producto.descuento)) {
                    detErrors.descuento = "El descuento no debe de ser mayor que el subtotal"
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.detalle_orden = detalleArray
            }
        }
        return errors;
    },
})(PeticionVentaForm);

const selector = formValueSelector('PeticionVentaForm');
PeticionVentaForm = connect(state => {
    let todos_productos = [];
    let simbolo = "Q. "
    let valor_iva = 0;
    let cliente = null;
    let descuento = 0;

    const productos = selector(state, 'detalle_orden');
    const moneda = selector(state, 'moneda');
    //const descuento = selector(state, 'descuento');
    const empresa = selector(state, 'empresa');
    const proveedor = selector(state, 'proveedor');

    const tiene_iva = true;

    if (moneda) {
        simbolo = getSimbolo(moneda)
    }
    if (productos) {
        todos_productos = productos;
    }
    if (proveedor) {
        cliente = proveedor;
    }
    let total = 0;
    let subtotal = 0;
    todos_productos.forEach(x => {
        if (!isNaN(x.cantidad) && !isNaN(x.precio_unitario)) {
            descuento += x.descuento;
        }
    });
    // Redondear el subtotal de cada ítem
    const subtotalesRedondeados = todos_productos.map(item => {
        const cantidad = parseFloat(item.cantidad);
        const precioUnitario = parseFloat(item.precio_unitario);
        const subtotal = cantidad * precioUnitario;
        
        return parseFloat(subtotal).toFixed(2);
    });

    // Calcular el subtotal general sumando los subtotales redondeados de cada ítem
    const subtotalGeneral = subtotalesRedondeados.reduce((total, subtotal) => total + parseFloat(subtotal), 0).toFixed(2);
    
    subtotal = subtotalGeneral
    total = subtotal
    if (descuento) {
        total = total - descuento;
    }
    

    return {
        total,
        simbolo,
        subtotal,
        empresa,
        productos,
        tiene_iva,
        valor_iva,
        cliente
    }
})(PeticionVentaForm);

export default PeticionVentaForm;
