import React, { Component, Fragment } from 'react'
import Select, { Creatable, Async } from 'react-select';
import Search from "./search"
import { SingleDatePicker } from 'react-dates';
import { Field } from 'redux-form';
import { renderField, AsyncSelectField } from '../renderField';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

import classNames from 'classnames';
import moment from 'moment';
import { api } from 'api';


const getClientes = (search, props) => {
    const params ={}
    params.search=search
    params.esCliente = true
    if(props.permisos.visualizar_mis_datos == true){
        params.visualizar_mis_datos = true
        params.id_user = props.id_user
    }
    return api.get("clientes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getPoss = (search) => {
    return api.get("poss", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class ToolbarReporteComisionesTarjetas extends Component {

    state = {
        dateStart: moment.now(),
        focusedStart: false,
        dateEnd: moment.now(),
        focusedEnd: false,
    }

    format = (array=[]) =>{
        const nuevoArray = [];
        array.forEach((item,index) => {
            nuevoArray[index]={"label": item.nombre , "value": item.id}
        });
        return nuevoArray
    }

    render() {
        const { cliente, poss, fecha_inicio, fecha_fin, inputSearch, search, changeFechaInicial, changeFechaFinal,
            changeCliente, changePoss
        } = this.props;

        return (
            <div className="row col-12 p-0">
                <div className="row col-12 m-0 text-lg-left">
                    <Fragment>
                        <div className={"col-lg-2 col-md-3 col-12 align-self-center d-flex flex-column"}>
                            <span className="t-azul-claro font-weight-bold">Fecha Inicial</span>
                            {/* <DatePicker
                                selected={new Date(fecha_inicio)}
                                onChange={value => {
                                    changeFechaInicial(moment(value))
                                }}
                                className="form-control text-date d-flex flex-column flex-1"
                                dateFormat="MMMM/yyyy"
                                locale="es"
                                showMonthYearPicker
                            /> */}
                            <SingleDatePicker
                                // className="form-control"
                                placeholder={"Fecha Inicio"}
                                date={fecha_inicio ? moment(fecha_inicio ) : null}
                                focused={this.state.focusedStart}
                                isOutsideRange={() => false}
                                onDateChange={(value) => {
                                    this.setState({dateStart:value})
                                    changeFechaInicial(value)
                                }}
                                onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                numberOfMonths={1}
                                id={"dateStart"}
                                readOnly={true}/>
                        </div>
                        <div className={"col-lg-2 col-md-3 col-12 align-self-center d-flex flex-column"}>
                            <span className="t-azul-claro font-weight-bold">Fecha Final</span>
                            {/* <DatePicker
                                selected={new Date(fecha_fin)}
                                onChange={value => {
                                    changeFechaFinal(moment(value))
                                }}
                                className="form-control text-date d-flex flex-column flex-1"
                                dateFormat="MMMM/yyyy"
                                locale="es"
                                showMonthYearPicker
                            /> */}
                            <SingleDatePicker
                                // className="form-control"
                                placeholder={"Fecha Final"}
                                date={fecha_fin ? moment(fecha_fin ) : null}
                                focused={this.state.focusedEnd}
                                isOutsideRange={() => false}
                                onDateChange={(value) => {
                                    this.setState({dateEnd:value})
                                    changeFechaFinal(value);
                                }}
                                onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                numberOfMonths={1}
                                id={"dateStart"}
                                readOnly={true}/>
                        </div>
                        <div className={"col-lg-3 col-md-3 col-12 align-self-center"}>
                            <span className="t-azul-claro font-weight-bold">Cliente</span>
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={cliente}
                                isSearchable={true}
                                loadOptions={(search) => getClientes(search, this.props)}
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["nombre"])}
                                type="text"
                                onChange={(e) => changeCliente(e)}
                                multi={true}
                                autoload={false}
                                cache={false}
                                className={classNames('react-select-container ')}
                                defaultOptions={true}
                                placeholder="Seleccionar.."
                            />
                        </div>
                        <div className={"col-lg-2 col-md-3 col-12 align-self-center"}>
                        <span className="t-azul-claro font-weight-bold">POSS</span>
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={poss}
                                isSearchable={true}
                                loadOptions={getPoss}
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["nombre"])}
                                type="text"
                                onChange={(e) => changePoss(e)}
                                multi={true}
                                autoload={false}
                                cache={false}
                                className={classNames('react-select-container ')}
                                defaultOptions={true}
                                placeholder="Seleccionar.."/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 align-self-end">
                            <span className="t-azul-claro font-weight-bold">&nbsp;&nbsp;</span>
                            <Search buscar={search} buscador={inputSearch} placeholder={'Buscar por: No. documento, O/V, Monto, No. factura'} />
                        </div>
                    </Fragment>
                </div>
            </div>
        )
    }
}
