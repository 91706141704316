import { connect } from 'react-redux';
import {
    actions
} from '../../../../../redux/modules/Reporte/reporteComisionesTarjetas';
import ReporteComisionesTarjetas from './ReporteComisionesTarjetas'

const mstp = state => {
    return {
        ...state.reporteComisionesTarjetas,
        permisos: state.login.me.permisos
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ReporteComisionesTarjetas)
