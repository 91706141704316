import React, { Component } from 'react';
import _ from 'lodash';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardSimple from '../../../Utils/Cards/cardSimple';
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import Grid from '../../../Utils/Grid/index';
import { TableHeaderColumn } from "react-bootstrap-table";
import { MESES } from '../../../../../utility/constants';
import ToolbarReporteComisionesTarjetas from '../../../Utils/Toolbar/ToolbarReporteComisionesTarjetas';
import moment from 'moment';
import { Redirect } from 'react-router';


class  DetalleGrid extends Component {

    render(){
        const { data, loader, listar: onPageChange, onSortChange, row_principal } = this.props

        return(
            <Grid hover striped data={{count:10, results:data}} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} pagination={false}>
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    width="2%"
                    dataSort
                    dataFormat={(cell, row) => ""}
                />
                <TableHeaderColumn
                    dataSort
                    width='18%'
                    dataField="afectado"
                    dataFormat={
                        (cell, row) =>(row_principal.vendedor)
                    }
                >
                    VENDEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="afectado"
                    width='8%'
                    dataSort
                    tdStyle={this.styleTotal}
                    dataFormat = {
                        (cell, row)=>(
                            <RenderMoneda monto={ row_principal.porcentaje_comision*100 } simbolo={'% '} />
                        )
                    }
                >
                    % COM.
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    width='10%'
                    dataField="afectado"
                    dataFormat={
                        (cell, row) =>(row_principal.no_doc_banco)
                    }
                >
                    No. Doc. Banco
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    width='10%'
                    dataFormat = {
                        (cell,row)=>(
                            <RenderDateTime fecha={row_principal.afectado.transaccion.fecha}></RenderDateTime>
                        )
                    }
                >
                    FECHA BANCOS
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="numero"
                    width='10%'
                    dataSort
                >
                    No. Factura
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="monto"
                    dataSort
                    width='13%'
                    dataFormat = {
                        (cell, row)=>(
                            <RenderMoneda monto={ cell } simbolo={'Q. '} />
                        )
                    }
                >
                    MONTO FAC.
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nit"
                    width='10%'
                    dataSort
                >
                    NIT
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    dataSort
                >
                    NOMBRE
                </TableHeaderColumn>
            </Grid>
        )
    }
}

export default class ReporteComisionesTarjetas extends Component {

    state = {
    }

    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    isExpandableRow(row) {
        if (row.id < 1) return false;
        return true
    }
    expandComponent(row) {
        return (
            <DetalleGrid row_principal={row} data={row.facturas} />
        );
    }
    styleTotal(cell, row) {
        if(row.id < 1){
            return {color: "#00334E", fontSize: 16, fontWeight: "bold"}
        }
        return ;
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, permisos, descargaArchivoComisionesTarjetasEP } = this.props;

        if(permisos.reporte_comisiones_tarjetas === false) {
            return <Redirect to={`/`} />
        }
        return (
            <LoadMask loading={loader} light type={"TailSpin"}>
                <CardSimple
                    titulo="comisiones">
                    <ToolbarReporteComisionesTarjetas
                        {...this.props}
                        search={this.props.search}
                        cliente={this.props.cliente}
                        poss={this.props.poss}
                        despacho={null}
                        fecha_inicio={this.props.fecha_inicio}
                        fecha_fin={this.props.fecha_fin}
                        changeCliente={this.props.changeCliente}
                        changePoss={this.props.changePoss}
                        changeFechaInicial={this.props.changeFechaInicio}
                        changeFechaFinal={this.props.changeFechaFin}/>

                        <div className="col-md-12 pt-3 d-flex justify-content-end">
                            <div>
                                <button
                                    className="btn btn-primary text-uppercase mr-1"
                                    onClick={() => {
                                        descargaArchivoComisionesTarjetasEP();
                                    }}>
                                    Descargar
                                </button>
                            </div>
                        </div>

                        <div className="col-md-12 pt-10 d-flex justify-content-end">
                            <div className="col-md-auto">
                                <span className="t-musgo font-weight-bold">
                                    TOTAL RECIBIDO:
                                </span>
                                <h4 className="t-azul m-none">{ <RenderMoneda monto={this.props.data.total_recibido} simbolo={'Q. '} /> }</h4>
                            </div>
                        </div>

                        <Grid
                            hover
                            data={data}
                            page={page}
                            loading={loader}
                            onPageChange={onPageChange}
                            onSortChange={onSortChange}
                            expandableRow={this.isExpandableRow}
                            expandComponent={this.expandComponent}
                            >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataSort
                                dataAlign="center"
                                tdStyle={this.styleTotal}
                                width="2%"
                                dataFormat={(cell, row) => ""}
                            />
                            {/* <TableHeaderColumn
                                isKey
                                dataField="id"
                                width="2%"
                                dataSort
                                tdSty_le={this.styleTotal}
                                // dataFormat={cell => ""}
                            /> */}
                            <TableHeaderColumn
                                dataField="cliente"
                                dataSort
                                tdStyle={this.styleTotal}
                            >
                                CLIENTE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {(cell, row)=> {
                                    if (row.id >= 1) return moment(cell).format("L")
                                    return ""
                                }}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="no_referencia"
                                dataSort
                                tdStyle={this.styleTotal}
                            >
                                No. REF.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="monto"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q'} />
                                    )
                                }
                            >
                                MONTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cuota"
                                dataSort
                                tdStyle={this.styleTotal}
                            >
                                CUOTA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ordenes_venta"
                                dataSort
                                tdStyle={this.styleTotal}
                            >
                                O/V
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="iva"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                IVA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="liquido"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                LIQUIDO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="comision"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                COMISIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="iva_comision"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                IVA COM.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="retension"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                RETENSIÓN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="recibido"
                                dataSort
                                tdStyle={this.styleTotal}
                                dataFormat = {
                                    (cell, row)=>(
                                        <RenderMoneda monto={ cell } simbolo={'Q. '} />
                                    )
                                }
                            >
                                RECIBIDO
                            </TableHeaderColumn>
                        </Grid>
                </CardSimple>
            </LoadMask>
        )
    }
}
