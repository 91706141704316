import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {
    Login,
    Profile,
    Registro,
    ResetPassword,
    VerifyPassword
} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';



import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
import {
    Listar as CuentasGrid,
    Nuevo as CuentasCrear,
} from '../js/common/components/app/Cuentas';
import {
    Listar as UsuarioGrid,
    Editar as UsuarioEditar
} from '../js/common/components/app/Users';

import {
    Listar as RolesGrid,
    Nuevo as RolesCrear
} from '../js/common/components/app/roles';

import{
    Listar as EmpresasGrid,
    Nuevo as EmpresasCrear
} from '../js/common/components/app/Empresas';

import{
    Listar as EstablecimientosGrid,
    Nuevo as EstablecimientosCrear
} from '../js/common/components/app/Establecimientos';

import {
    Listar as TransaccionesGrid,
    Nuevo as TransaccionCrear,
    AsignarFlotante
} from '../js/common/components/app/Transacciones';

import{
    ListarTipoProducto,
    TipoProductoCrear
} from './common/components/app/TipoProductos';

import {
    ProductoGrid,
    ProductoCrear
} from './common/components/app/Producto';

import {
    BodegaListar,
    BodegaCrear
} from './common/components/app/Bodega';

import {
    ListarClientes,
    DetalleCliente,
    CrearCliente,
    ListarProveedores,
} from './common/components/app/Clientes';

import {
    VendedoresGrid,
    VendedorCrear
} from './common/components/app/Vendedores';

import {
    PilotoCrear,
    PilotosGrid
} from './common/components/app/Pilotos';

import {
    NuevoPagoItinerario,
    PagoItinerarioGrid
} from './common/components/app/PagosItinerarios'

import {
    OrdenCompraGrid,
    OrdenCompraCrear,
    DetalleOrdenCompra,
    IngresoMateria
} from './common/components/app/OrdenCompra';

import {
    ListartPeticiones,
    CrearPeticiones,
    DetallePetciones,
} from './common/components/app/PeticionMateriales';

import {
    BitacoraDetalle,
    BitacoraGrid
} from './common/components/app/Bitacora';

import {
    ListarPV,
    CrearPV,
    DetallePV,
} from './common/components/app/PeticionVenta';

import {
    ListarCategorias
} from './common/components/app/TipoCategoria'

import {
    GastoCrear,
    GastoGrid,
    GastoDetalle
} from './common/components/app/Gastos';

import { detalleLiquidacion } from "./common/components/app/liquidaciones";

import {
    ListarCajaChica,
    DetalleCajaChica,
} from  './common/components/app/CajaChica';

import {
    ListarRegiones,
    NuevaRegiones
} from './common/components/app/Regiones'

import {
    ListarOrdenesVenta,
    DetalleOrdenVenta,
    DespachoOrdenVenta,
    FacturarOrndeVenta,
} from './common/components/app/OrdenVenta'

import {
    ListarPuestos,
    NuevoPuesto
} from './common/components/app/Puestos'

import {
    NotificacionesListar
} from './common/components/app/Notificaciones'

import {
    AusenciasGrid,
    AusenciasCrear,
} from './common/components/app/Ausencias'

/* REPORTES */
import {
    DeudaReporte,
    DeudaInternaReporte,
    CuentasporCOP,
    CuentasporC,
    IngresoEgreso,
    IngresoEgresoInterno,
    PagoFlotanteReporte,
    AsignarTarjeta,
    AsignarCheque,
    DeudaDetallEmpresa,
    DeudaDetalleEmpresaInterna,
    OrdenesporC,
    OrdenesporCOP,
    ReporteVehiculos,
    ValesGasolina,
    crearGastoVale,
    ReporteCobros,
    ReporteVentas,
    ReporteCuentas,
    ReporteDespachosBancos,
    ReporteComisionesTarjetas,
    ReporteCuentasPagar,
    ReporteProductos,
    ReporteProduccion,
    ReporteConsumo,
    ReportePreciocliente,
    ReportePrecioProducto,
    ReportePilotos,
    ReporteCostoInventario,
    ReporteMateriaPrima,
    ReporteLoteInventario,
    ReporteCostoVenta,
    ReporteIngresoProducto,
    AusenciasReporte,
    PlanillaporEmpleado,
    PlanillaporEmpresa,
    DetallePagoPlanilla,
} from './common/components/app/Reportes';

import {
    AumentoProductoGrid
} from './common/components/app/Reportes/ProductoPrecios';

import {
    PreciosInternosGrid
} from './common/components/app/Reportes/PreciosInternos';

import {
    PossCrear,
    PossGrid
} from './common/components/app/Poss';

import {
    VentaInternaGrid,
    VentaInternaCrear,
    VentaInternaDetalle,
    VentaInternaDespacho
} from './common/components/app/SolicitudInternaMateriales'

import {
    Listar as ListartBonificaciones,
    NuevaBonificacion
} from './common/components/app/Bonificacion';

import {
    ListarEmpleados,
    NuevoEmpleado,
    DetalleEmpleado
} from './common/components/app/Empleados';
import {
    EstadoBodega,
    DespacharBodega,
    DetalleDespachoEstado
} from './common/components/app/estado_bodega';

import {
    ListarPlanillas,
    CrearPagoPlanilla,
    EdicionBonosPlanilla,
    DetallePlanilla,
} from './common/components/app/Planilla';

import {
    ActividadCrear,
    ActividadGrid
} from './common/components/app/Actividades';

import {
    EmpresasGrid as GridProduccionEmpresa,
    Colaproduccion,
    listaOrdenesProduccion,
    NuevaProducion,
    ReportarProduccion,
    CambioReceta,
    NuevaProducionDev,
    DetalleOP
} from './common/components/app/Produccion';

import {
    AsignacionesGrid,
    AsignacionesCrear
} from './common/components/app/AsignacionActividades';

import {
    DevolucionCrear,
    DevolucionGrid,
    DevolucionDetalle,
    DevolucionDespacho
} from './common/components/app/Devolucion';

import {
    DevolucionProCrear,
    DevolucionProGrid,
    DevolucionProDetalle,
    DevolucionProDespacho
} from './common/components/app/DevolucionProv';

import { VehiculosGrid, VehiculosCrear } from './common/components/app/VehiculosMaquinaria';

import { ValesGrid,
    ValesCrear,
    ValesDetalle,
    ValePreview
} from './common/components/app/valesGasolina';

import {
    itinerarioGrid,
    detalleItinerario,
    crearItinerario,
    DesgloseGastos,
    listadoProductos
 } from './common/components/app/itinerarios';

 import {
    DespachoPlantaCrear,
    DespachoPlantaGrid,
    DetalleDespachoPlanta
} from './common/components/app/DespachosPlanta';

import {
    BodegasGrid
} from './common/components/app/BodegaUsuario';

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />


                {/* #recuperacion de password */}
                <Route exact path="/ResetPassword" component={ResetPassword} />
                <Route exact path="/verify/:token" component={VerifyPassword} />
                {/* home */}
                <ProtectedRoute exact path="/" component={Demo} />

                {/* Usuarios */}
                <ProtectedRoute exact path="/usuarios" component={UsuarioGrid} />
                <ProtectedRoute exact path="/usuario/nuevo" component={UsuarioEditar} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/usuario/:id" component={UsuarioEditar} />

                {/* Categoriga gastos */}
                <ProtectedRoute exact path="/admin_tipocategoria" component={ListarCategorias} />

                {/* gastos */}
                <ProtectedRoute exact path="/gastos" component={GastoGrid} />
                <ProtectedRoute exact path="/gasto/nuevo" component={GastoCrear} />
                <ProtectedRoute exact path="/gasto/:id" component={GastoCrear} />
                <ProtectedRoute exact path="/gasto/detalle/:id" component={GastoDetalle} />

                {/* roles */}
                <ProtectedRoute exact path="/roles" component={RolesGrid} />
                <ProtectedRoute exact path="/role/nuevo" component={RolesCrear} />
                <ProtectedRoute exact path="/role/:id" component={RolesCrear} />

                {/* empresas */}
                <ProtectedRoute exact path="/empresas" component={EmpresasGrid} />
                <ProtectedRoute exact path="/empresa/nuevo" component={EmpresasCrear} />
                <ProtectedRoute exact path="/empresa/:id" component={EmpresasCrear} />

                {/* establecimientos */}
                <ProtectedRoute exact path="/establecimientos" component={EstablecimientosGrid} />
                <ProtectedRoute exact path="/establecimiento/nuevo" component={EstablecimientosCrear} />
                <ProtectedRoute exact path="/establecimiento/:id" component={EstablecimientosCrear} />

                {/* cuentas */}
                <ProtectedRoute exact path="/cuentas" component={CuentasGrid} />
                <ProtectedRoute exact path="/cuenta/nueva" component={CuentasCrear} />
                <ProtectedRoute exact path="/cuenta/:id" component={CuentasCrear} />
                <ProtectedRoute exact path="/transaccion/flotante" component={AsignarFlotante} />
                <ProtectedRoute exact path="/transaccion/:id" component={TransaccionesGrid} />
                <ProtectedRoute exact path="/transaccion/nueva/:id" component={TransaccionCrear} />
                {/* caja chica */}
                <ProtectedRoute exact path="/cajas" component={ListarCajaChica} />
                <ProtectedRoute exact path="/caja/:id" component={DetalleCajaChica} />
                <ProtectedRoute exact path="/caja/:id/liquidacion/:idLiquidacion" component={detalleLiquidacion} />
                <ProtectedRoute exact path="/liquidaciones/:id/detalle" component={detalleLiquidacion} />

                {/* Clientes y Proveedores */}
                <ProtectedRoute exact path="/clientes" component={ListarClientes} />
                <ProtectedRoute exact path="/clientes/nuevo" component={CrearCliente} />
                <ProtectedRoute exact path="/clientes/:id" component={DetalleCliente} />
                <ProtectedRoute exact path="/clientes/:id/editar" component={CrearCliente} />
                <ProtectedRoute exact path="/proveedores" component={ListarProveedores} />
                <ProtectedRoute exact path="/proveedores/nuevo" component={CrearCliente} />
                <ProtectedRoute exact path="/proveedores/:id" component={DetalleCliente} />
                <ProtectedRoute exact path="/proveedores/:id/editar" component={CrearCliente} />


                {/* Tipo de producto */}
                <ProtectedRoute exact path="/tipoproductos" component={ListarTipoProducto} />
                <ProtectedRoute exact path="/tipoproducto/nuevo" component={TipoProductoCrear} />
                <ProtectedRoute exact path="/tipoproducto/:id" component={TipoProductoCrear} />

                {/* Producto */}
                <ProtectedRoute exact path="/productos" component={ProductoGrid} />
                <ProtectedRoute exact path="/producto/nuevo" component={ProductoCrear} />
                <ProtectedRoute exact path="/producto/:id" component={ProductoCrear} />

                {/* Bodega */}
                <ProtectedRoute exact path="/bodegas" component={BodegaListar} />
                <ProtectedRoute exact path="/bodega/nuevo" component={BodegaCrear} />
                <ProtectedRoute exact path="/bodega/:id" component={BodegaCrear} />

                {/* Estado de bodega */}
                <ProtectedRoute exact path="/estado_bodega/:id" component={EstadoBodega} />
                <ProtectedRoute exact path="/estado_bodega/crear/:id" component={DespacharBodega} />
                <ProtectedRoute exact path="/estado_bodega/:bodega/despacho/:id" component={DetalleDespachoEstado} />


                {/* Vendedores */}
                <ProtectedRoute exact path="/vendedores" component={VendedoresGrid} />
                <ProtectedRoute exact path="/vendedor/nuevo" component={VendedorCrear} />
                <ProtectedRoute exact path="/vendedor/:id" component={VendedorCrear} />

                {/* Pilotos */}
                <ProtectedRoute exact path="/pilotos" component={PilotosGrid} />
                <ProtectedRoute exact path="/pilotos/nuevo" component={PilotoCrear} />
                <ProtectedRoute exact path="/pilotos/:id" component={PilotoCrear} />

                {/* ordenes de compra */}
                <ProtectedRoute exact path="/ordencompras" component={OrdenCompraGrid} />
                <ProtectedRoute exact path="/ordencompra/nuevo" component={OrdenCompraCrear} />
                <ProtectedRoute exact path="/ordencompra/:id" component={OrdenCompraCrear} />
                <ProtectedRoute exact path="/ordencompra/:id/detalle" component={DetalleOrdenCompra} />
                <ProtectedRoute exact path="/ingreso/ordencompra/:id" component={IngresoMateria}/>

                {/* peticiones de material */}
                <ProtectedRoute exact path="/peticiones" component={ListartPeticiones} />
                <ProtectedRoute exact path="/peticiones/nuevo" component={CrearPeticiones} />
                <ProtectedRoute exact path="/peticiones/:id" component={CrearPeticiones} />
                <ProtectedRoute exact path="/peticiones/:id/detalle" component={DetallePetciones} />
                {/* peticiones de venta */}
                <ProtectedRoute exact path="/peticionesventa" component={ListarPV}/>
                <ProtectedRoute exact path="/peticionesventa/nuevo" component={CrearPV} />
                <ProtectedRoute exact path="/peticionesventa/:id" component={CrearPV} />
                <ProtectedRoute exact path="/peticionesventa/:id/detalle" component={DetallePV} />
                {/* ordenes de venta */}
                <ProtectedRoute exact path="/ordenesventa" component={ListarOrdenesVenta}/>
                <ProtectedRoute exact path="/ordenesventa/:id" component={CrearPV} />
                <ProtectedRoute exact path="/ordenesventa/:id/detalle" component={DetalleOrdenVenta}/>
                <ProtectedRoute exact path="/ordenesventa/:id/devolucion/:id" component={DevolucionCrear}/>
                <ProtectedRoute exact path="/despacho/ordenventa/:id" component={DespachoOrdenVenta}/>
                <ProtectedRoute exact path="/facturar/ordenventa/:id" component={FacturarOrndeVenta}/>

                
                
                {/* Itinerario */}
                <ProtectedRoute exact path="/itinerario" component={itinerarioGrid}/>
                <ProtectedRoute exact path="/itinerario/:id/detalle" component = {detalleItinerario} />
                <ProtectedRoute exact path="/itinerario/:id" component = {crearItinerario} />
                <ProtectedRoute exact path="/itinerario/:id/gastos" component = {DesgloseGastos} />
                <ProtectedRoute exact path="/itinerario/nuevo" component = {crearItinerario} />
                <ProtectedRoute exact path="/itinerario/nuevo/orden_compra/:id/" component = {listadoProductos} />
                <ProtectedRoute exact path="/pagos_itinerario/nuevo" component = {NuevoPagoItinerario}/>
                <ProtectedRoute exact path="/pagos_itinerario/:id" component = {NuevoPagoItinerario}/>
                <ProtectedRoute exact path="/pagos_itinerario" component = {PagoItinerarioGrid}/>


                {/* Ventas internas */}
                <ProtectedRoute exact path="/solicitud_interna" component={VentaInternaGrid}/>
                <ProtectedRoute exact path="/solicitud_interna/nuevo" component={VentaInternaCrear}/>
                <ProtectedRoute exact path="/solicitud_interna/:id" component={VentaInternaCrear}/>
                <ProtectedRoute exact path="/solicitud_interna/:id/detalle" component={VentaInternaDetalle}/>
                <ProtectedRoute exact path="/despacho/solicitud_interna/:id" component={VentaInternaDespacho}/>

                {/* regiones */}
                <ProtectedRoute exact path="/regiones" component={ListarRegiones}/>
                <ProtectedRoute exact path="/regiones/nueva" component={NuevaRegiones}/>
                <ProtectedRoute exact path="/regiones/:id" component={NuevaRegiones}/>

                {/* PLANILLA */}
                {/* Puestos de trabajo */}
                <ProtectedRoute exact path="/puestos" component={ListarPuestos}/>
                <ProtectedRoute exact path="/puesto/nuevo" component={NuevoPuesto}/>
                <ProtectedRoute exact path="/puesto/:id" component={NuevoPuesto}/>
                {/* bonificaciones */}
                <ProtectedRoute exact path="/bonificaciones" component={ListartBonificaciones}/>
                <ProtectedRoute exact path="/bonificacion_descuento/nuevo" component={NuevaBonificacion}/>
                <ProtectedRoute exact path="/bonificacion_descuento/:id" component={NuevaBonificacion}/>
                {/* Empleados */}
                <ProtectedRoute exact path="/empleados" component={ListarEmpleados}/>
                <ProtectedRoute exact path="/empleados/nuevo" component={NuevoEmpleado}/>
                <ProtectedRoute exact path="/empleados/:id" component={NuevoEmpleado}/>
                <ProtectedRoute exact path="/empleados/:id/detalle" component={DetalleEmpleado}/>
                {/* pago de planilla */}
                <ProtectedRoute exact path="/planilla" component={ListarPlanillas}/>
                <ProtectedRoute exact path="/planilla/EdicionBonos" component={EdicionBonosPlanilla}/>
                <ProtectedRoute exact path="/planilla/nuevo" component={CrearPagoPlanilla}/>
                <ProtectedRoute exact path="/planilla/:id" component={CrearPagoPlanilla}/>
                <ProtectedRoute exact path="/planilla/:id/detalle" component={DetallePlanilla}/>


                {/* actividades */}
                <ProtectedRoute exact path="/actividades" component={ActividadGrid} />
                <ProtectedRoute exact path="/actividad/nuevo" component={ActividadCrear} />
                <ProtectedRoute exact path="/actividad/:id" component={ActividadCrear} />

                {/* produccion */}
                <ProtectedRoute exact path="/produccion" component={GridProduccionEmpresa} />
                <ProtectedRoute exact path="/produccion/:id" component={Colaproduccion} />
                <ProtectedRoute exact path="/produccion/orden/:idOP" component={DetalleOP} />
                <ProtectedRoute exact path="/produccion/ordenes/:id" component={listaOrdenesProduccion} />
                <ProtectedRoute exact path="/produccion/:empresa/orden" component={NuevaProducion} />
                <ProtectedRoute exact path="/produccion/:empresa/orden/:orden" component={NuevaProducion} />
                <ProtectedRoute exact path="/produccion/:empresa/sim" component={NuevaProducion} />
                <ProtectedRoute exact path="/produccion/:empresa/dev" component={NuevaProducionDev} />
                <ProtectedRoute exact path="/reportar_produccion/edicionreceta" component={CambioReceta} />
                <ProtectedRoute exact path="/reportar_produccion/:empresa" component={ReportarProduccion} />

                {/* Asignacion de actividades */}
                <ProtectedRoute exact path="/asignacion_actividades" component={AsignacionesGrid} />
                <ProtectedRoute exact path="/asignacion_actividades/nuevo" component={AsignacionesCrear} />
                {/* Ausencias de empleados */}
                <ProtectedRoute exact path="/ausencias" component={AusenciasGrid} />
                <ProtectedRoute exact path="/ausencias/nuevo" component={AusenciasCrear} />


                {/* devoluciones */}
                <ProtectedRoute exact path="/devoluciones" component={DevolucionGrid} />
                <ProtectedRoute exact path="/devolucion/nuevo" component={DevolucionCrear} />
                <ProtectedRoute exact path="/devolucion/:id" component={DevolucionCrear} />
                <ProtectedRoute exact path="/devolucion/:id/despacho" component={DevolucionDespacho} />

                {/* devoluciones proveedores */}
                <ProtectedRoute exact path="/devolucionespro" component={DevolucionProGrid} />
                <ProtectedRoute exact path="/devolucionpro/nuevo" component={DevolucionProCrear} />
                <ProtectedRoute exact path="/devolucionpro/:id" component={DevolucionProCrear} />
                <ProtectedRoute exact path="/devolucionpro/:id/despacho" component={DevolucionProDespacho} />
                {/* <ProtectedRoute exact path="/devolucionpro/:id/despacho" component={DevolucionDespacho} /> */}

                {/* Vehiculos y Maquinaria */}
                <ProtectedRoute exact path="/vehiculos_maquinaria" component={VehiculosGrid} />
                <ProtectedRoute exact path="/vehiculos_maquinaria/nuevo" component={VehiculosCrear} />
                <ProtectedRoute exact path="/vehiculos_maquinaria/:id" component={VehiculosCrear} />

                {/* Vales de Gasolina */}
                <ProtectedRoute exact path="/vales_gasolina" component={ValesGrid} />
                <ProtectedRoute exact path="/vales_gasolina/nuevo" component={ValesCrear} />
                <ProtectedRoute exact path="/vales_gasolina/:id" component={ValesCrear} />
                <ProtectedRoute exact path="/vales_gasolina/:id/detalle" component={ValesDetalle} />
                <ProtectedRoute exact path="/vales_gasolina/:id/documento" component={ValePreview} />

                {/* Manejo de reportes */}
                {/* Manejo de bitácoras */}
                <ProtectedRoute exact path="/reporte_bitacora_detalle/:id/detalle" component={BitacoraDetalle} />
                <ProtectedRoute exact path="/reporte_bitacoras" component={BitacoraGrid} />

                {/* reportes de cuentas */}
                <ProtectedRoute exact path="/reporte_deuda" component={DeudaReporte} />
                <ProtectedRoute exact path="/reporte_deuda/:id" component={DeudaDetallEmpresa} />
                <ProtectedRoute exact path="/reporte_deuda_cliente_proveedor/:id" component={DeudaDetallEmpresa} />

                <ProtectedRoute exact path="/reporte_deuda_interna" component={DeudaInternaReporte} />
                <ProtectedRoute exact path="/reporte_deuda_interna/:id" component={DeudaDetalleEmpresaInterna} />

                <ProtectedRoute exact path="/cuentas_por_cobrar" component={CuentasporC} />
                <ProtectedRoute exact path="/cuentas_por_pagar" component={CuentasporCOP} />
                <ProtectedRoute exact path="/ordenes_por_cobrar/:idclipro" component={OrdenesporC} />
                <ProtectedRoute exact path="/ordenes_por_pagar/:idclipro" component={OrdenesporCOP} />

                <ProtectedRoute exact path="/reporte_ingreso" component={IngresoEgreso} />
                <ProtectedRoute exact path="/reporte_ingreso_interno" component={IngresoEgresoInterno} />
                <ProtectedRoute exact path="/reporte_pago_flotante" component={PagoFlotanteReporte} />
                <ProtectedRoute exact path="/reporte_asignar_tarjeta" component={AsignarTarjeta} />
                <ProtectedRoute exact path="/reporte_asignar_cheque" component={AsignarCheque} />
                <ProtectedRoute exact path="/transaccion/flotante" component={AsignarFlotante} />

                {/* Reporte maquinaria y vehiculos */}
                <ProtectedRoute exact path="/reporte_maquinaria_vehiculos" component={ReporteVehiculos} />
                {/*  Reporte de vales de gasolina */}
                <ProtectedRoute exact path="/reporte_vales_gasolina" component={ValesGasolina} />
                <ProtectedRoute exact path="/reporte_vales_gasolina/gasto" component={crearGastoVale} />
                {/* Reporte Cobros, ventas, cuenta por cobrar */}
                <ProtectedRoute exact path="/reporte_cobros" component={ReporteCobros} />
                <ProtectedRoute exact path="/reporte_cuentas" component={ReporteCuentas} />
                <ProtectedRoute exact path="/reporte-despachos-bancos" component={ReporteDespachosBancos} />
                <ProtectedRoute exact path="/reporte-comisiones-tarjetas" component={ReporteComisionesTarjetas} />
                <ProtectedRoute exact path="/reporte_cuentas_pagar" component={ReporteCuentasPagar} />
                <ProtectedRoute exact path="/reporte_ventas" component={ReporteVentas} />
                <ProtectedRoute exact path="/reporte_aunsecias" component={AusenciasReporte} />
                <ProtectedRoute exact path="/reporte_pagos_Empleado" component={PlanillaporEmpleado} />
                <ProtectedRoute exact path="/reporte_pagos_Empresa" component={PlanillaporEmpresa} />
                <ProtectedRoute exact path="/detalle_planilla/:id" component={DetallePagoPlanilla} />

                {/* notificaciones */}
                <ProtectedRoute exact path="/notificaciones" component={NotificacionesListar} />
                {/* Reporte aumento precios productos */}
                <ProtectedRoute exact path="/aumento_productos" component={AumentoProductoGrid} />
                {/* Reporte cambio precios internos */}
                <ProtectedRoute exact path="/precios_internos" component={PreciosInternosGrid} />
                {/* Reporte de Productos */}
                <ProtectedRoute exact path="/reporte_productos" component={ReporteProductos}/>
                {/* Reporte de Produccion */}
                <ProtectedRoute exact path="/reporte_produccion" component={ReporteProduccion}/>
                <ProtectedRoute exact path="/reporte_consumo" component={ReporteConsumo}/>
                {/* Reporte de clientes */}
                <ProtectedRoute exact path="/precios_clientes" component={ReportePreciocliente} />
                {/* Reporte de precio de productos */}
                <ProtectedRoute exact path="/precios_productos" component={ReportePrecioProducto} />
                {/* Reporte de actividad de pilotos */}
                <ProtectedRoute exact path="/reporte_pilotos" component={ReportePilotos} />
                {/* Reporte de costo de productos vs ventas */}
                <ProtectedRoute exact path="/reporte_costovsventas" component={ReporteCostoVenta}/>
                {/* Reporte de ingresos por producto */}
                <ProtectedRoute exact path="/reporte_ingresos_producto" component={ReporteIngresoProducto}/>
                {/* Reporte de Costos de inventario */}
                <ProtectedRoute exact path="/costos_inventario" component={ReporteCostoInventario}/>
                <ProtectedRoute exact path="/costos_inventario/:id" component={ReporteLoteInventario}/>

                {/* Reporte de materia prima */}
                <ProtectedRoute exact path="/reporte_materia_prima" component={ReporteMateriaPrima}/>
                <ProtectedRoute exact path="/reporte_materia_prima/:id" component={ReporteMateriaPrima}/>

                {/* despachosplanta */}
                <ProtectedRoute exact path="/despachosplanta" component={DespachoPlantaGrid} />
                <ProtectedRoute exact path="/despachosplanta/nuevo" component={DespachoPlantaCrear} />
                <ProtectedRoute exact path="/despachosplanta/:id/detalle" component={DetalleDespachoPlanta} />
            

                <ProtectedRoute exact path="/poss" component={PossGrid} />
                <ProtectedRoute exact path="/poss/nueva" component={PossCrear} />
                <ProtectedRoute exact path="/poss/:id" component={PossCrear} />


                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />

                <ProtectedRoute exact path="/bodega_usuario/:id/:tipo" component={BodegasGrid} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);

