import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {
    renderField,
    AsyncSelectField,
    renderFieldRadio,
    renderSelectField,
    renderCurrency,
    renderTextArea,
    renderSelecttree,
    renderFieldCheck,
    SelectField
} from '../../../../Utils/renderField';
import { TipoTransaccion,TipoDocumentoPago } from '../../../../../../utility/constants';
import { getSimbolo} from "../../../../Utils/renderField/renderTableField";
import  {renderDropdownDateField} from "../../../../Utils/renderField/otherrenders";
import {api} from 'api';


const getPoss = (search) => {
    return api.get("poss", { search, tipo: 1 }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getTotalesTipoDocumentos = (props) => {
    let params = {clipro: true, idClienteProveedor: props.idClienteProveedor};
    return api.get("reportes/pendiente_tipo_documentos", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};


let PagoCreditoForm = (props) => {
    const [isPagoAnticipadoChecked, setIsPagoAnticipadoChecked] = useState(false);
    const [cuotasOptions, setCuotasOptions] = useState([]);

    const { handleSubmit, cuentas, texto_boton_movimiento, simbolo, tipo_documento,
        cerrarModal, es_gasto, tipo_cuenta, tipo_gastos, esCobro,saldo_cuenta, esCheque, es_tarjeta,
        idClienteProveedor
    } = props;
    return (
        <form name="PagoCreditoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div style={{border: "1px solid #e8e8e8", padding:"10px"}}>
                <div className="d-flex justify-content-center">
                    <h5 className="t-primary text-uppercase align-self-center">{props.titulo_modal? props.titulo_modal:""}</h5>
                </div>
                <div className="row col-md-12 m-0 p-0 d-flex justify-content-center">
                    <div className={`form-group has-feedback  col-md-3 col-12 d-flex justify-content-center`}>
                        <Field
                            name="tipo_cuenta"
                            label="Cuenta"
                            component={renderFieldRadio}
                            type="radio"
                            value={"true"}
                            className="form-control" />
                    </div>
                    <div className={`form-group has-feedback col-md-3  col-12 d-flex justify-content-center`}>
                        <Field
                            value={"false"}
                            name="tipo_cuenta"
                            label="Mi Caja Chica"
                            component={renderFieldRadio}
                            type="radio"
                            className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row col-12 m-0 p-0">

            <div className={`form-group has-feedback  col-md-12  col-12 `}>
                    <label className="t-azul" htmlFor="es_pago_anticipado">Pago Anticipado</label>
                    <Field
                        name="es_pago_anticipado"
                        component={renderFieldCheck}
                        labelKey="label" valueKey="value"
                        type="checkbox"
                        className="form-control"
                        onChange={(e) => {
                            setIsPagoAnticipadoChecked(e.target.checked);
                        }}
                        disabled={props.saldo_cuenta < 0}
                    />
                </div>

                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul" htmlFor="nombre">Fecha</label>
                    <Field
                        name="fecha"
                        selectedDate={new Date()}
                        maxDate="2030-12-31"
                        minDate="2018-01-01"
                        className="form-control"
                        component={renderDropdownDateField}
                    />
                </div>
                {/* <div className={`form-group has-feedback  col-md-6  col-12 `}>
                </div> */}
                <div className={`form-group has-feedback  col-md-6  col-12 `}>
                    <label className="t-azul" htmlFor="label_monto">Tipo de Documento</label>
                    <Field
                        name="tipo_documento_pago"
                        label="tipo_documento_pago"
                        labelKey="label_monto"
                        valueKey="value"
                        component={AsyncSelectField}
                        loadOptions={() => {return getTotalesTipoDocumentos(props)}}
                        className="form-control"
                        disabled={isPagoAnticipadoChecked}
                    />
                </div>
            </div>

            {
                (tipo_cuenta) && (
                    <div className="row col-12 m-0 p-0">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="tipo_documento">Tipo de Movimiento</label>
                            <Field
                                name="tipo_documento"
                                label="tipo_documento"
                                labelKey="label" valueKey="value"
                                component={renderSelectField}
                                options={TipoTransaccion}
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="no_documento">{tipo_documento}</label>
                            <Field name="no_documento" label="no_documento" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                )
            }
            {
                (tipo_gastos) && (
                    <div className="row col-12 m-0 p-0">
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul" htmlFor="nombre">Categoría de Gasto</label>
                            <Field
                                name="tipo_gasto"
                                label="nombre"
                                component={renderSelecttree}
                                showSearch = { true }
                                filtro = "title"
                                options={tipo_gastos}
                                type="text"
                                valueKey="value"
                                labelKey="label"
                                className="form-control" />
                        </div>
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                        </div>
                    </div>
                )
            }

            <div className="row col-12 m-0 p-0">
                <div className="form-group has-feedback col-md-6 col-12">
                    <label className="t-azul"  htmlFor="monto">Monto</label>
                    <Field
                        name="monto"
                        label="monto"
                        prefix={simbolo}
                        component={renderCurrency}
                        parse={cell => parseFloat(cell)}
                        className="form-control"
                    />
                </div>
                {
                    (tipo_cuenta && !es_tarjeta) && (
                        <div className={`form-group has-feedback  col-md-6  col-12 `}>
                            <label className="t-azul"  htmlFor="numero_cuenta">No. de Cuenta</label>
                            <Field
                                name="numero_cuenta"
                                label="numero_cuenta"
                                labelKey="label" valueKey="id"
                                component={renderSelectField}
                                options={cuentas}
                                className="form-control"
                            />
                        </div>
                    )
                }
                {
                    (es_tarjeta) && (
                        <Fragment>
                            <div className={`form-group has-feedback  col-md-6  col-12 `}>
                                <label className="t-azul" htmlFor="nombre">POS</label>
                                <Field
                                    name="poss"
                                    label="poss"
                                    component={AsyncSelectField}
                                    loadOptions={getPoss}
                                    type="text"
                                    valueKey="id"
                                    labelKey="nombre"
                                    onChange={(e) => {
                                        setCuotasOptions(e.cuotas);
                                    }}
                                    isClearable={false}
                                    className="form-control"/>
                            </div>
                            <div className={`form-group has-feedback col-md-6  col-12`}></div>
                            <div className={`form-group has-feedback col-md-6  col-12`}>
                                <label className="t-azul" htmlFor="correo">Cuotas</label>
                                <Field
                                    name="cuota"
                                    label="cuota"
                                    valueKey="cuota"
                                    labelKey="cuota"
                                    component={SelectField}
                                    options={cuotasOptions}
                                    onChange={(e) => {
                                    }}
                                    className="form-control"/>
                            </div>
                        </Fragment>
                    )
                }

            </div>

            {
                (esCobro && esCheque) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Fecha de Cobro</label>
                            <Field
                                name="fecha_cobro"
                                selectedDate={new Date()}
                                maxDate="2030-12-31"
                                minDate="2018-01-01"
                                className="form-control"
                                component={renderDropdownDateField}
                            />
                        </div>
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="nombre">Banco de referencia</label>
                            <Field
                                name="banco_referencia"
                                className="form-control"
                                component={renderField}
                            />
                        </div>
                    </div>
                )
            }
            {
                (es_gasto) && (
                    <div className="row col-12 m-0 p-0">
                        <div className="form-group has-feedback  col-sm-12  col-12">
                            <label className="t-azul" htmlFor="descripcion">Descripción</label>
                            <Field
                                name="descripcion"
                                label="descripcion"
                                component={renderTextArea}
                                type="text"
                                className="form-control" />
                        </div>
                    </div>
                )
            }

            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around " >
                        <button type="button" onClick={cerrarModal} className="btn btn-secondary  align-self-center">CANCELAR</button>
                        <button type="submit" className="btn btn-primary m-1 align-self-center">
                            {
                                (texto_boton_movimiento) ? (`${texto_boton_movimiento}`) : ("AGREGAR PAGO")
                            }
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};


PagoCreditoForm = reduxForm({
    form: 'PagoCreditoForm', // a unique identifier for this form
    initialValues: {
        tipo_cuenta: 'true',
        fecha: new Date(),
        fecha_cobro: new Date(),
    },
    validate: (data, props) => {
        const errors = {};

        if (!data.fecha) {
            errors.fecha = "Campo requerido.";
        }
        if (!data.numero_cuenta) {
            errors.numero_cuenta = "Campo requerido.";
        }
        if (!data.numero_cuenta) {
            errors.numero_cuenta = "Campo requerido.";
        }
        if (!data.tipo_documento) {
            errors.tipo_documento = 'Campo requerido';
        }
        if (!data.no_documento) {
            errors.no_documento = 'Campo requerido';
        }
        if (!data.monto) {
            errors.monto = 'Campo requerido.';
        }
        if (props.isPagoAnticipadoChecked) {
            if (data.monto && parseFloat(data.monto) > parseFloat(data.tipo_documento_pago.monto_total)) {
                errors.monto = 'El monto tiene que ser menor al monto pendiente del documento.';
            }
            if (!data.tipo_documento_pago) {
                errors.tipo_documento_pago = "Campo requerido.";
            }
        }

        return errors;
    },
})(PagoCreditoForm);


const selector = formValueSelector('PagoCreditoForm');
PagoCreditoForm = connect(state => {
    const tipo_movimiento = selector(state, 'tipo_documento')
    const tipo_cuenta_form = selector(state, 'tipo_cuenta')

    let tipo_cuenta = true
    let esCheque = false;
    let es_tarjeta = false;
    if(tipo_cuenta_form == "false"){
        tipo_cuenta = false
    }
    let tipo_documento = 'Documento de Registro'
    if(tipo_movimiento){
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento
        });
        tipo_documento = seleccionado.ayuda
        if (seleccionado.value == 2){esCheque = true}
        if(seleccionado.value == 5){es_tarjeta = true}
    }
    return{
        tipo_documento,
        tipo_cuenta,
        esCheque,
        es_tarjeta
    }


})(PagoCreditoForm);


export default PagoCreditoForm;
